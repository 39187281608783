.evaluator {
  *:focus-visible {
    outline: -webkit-focus-ring-color auto 2px !important;
	outline-offset: 2px;
  }
  main {
    position: relative;
    background: white;
    padding-left: 15px;
    padding-bottom: 20px;

    .breadcrumbs {
      color: $breadcrumbs-grey;
      padding-top: 10px;
      padding-bottom: 8px;
      border-bottom: $criteria-menu-border;
      font-size: 11px;

      a {
        color: $breadcrumbs-grey;
        font-weight: normal;
      }
      b > a {
        font-weight: bold;
      }
    }

    .title-container {
      margin: 10px 0;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      &.off {
        display: none;
      }

      .header {
        float: left;
        h1 {
          font-weight: normal;
          font-size: 33px;
          margin-top: 2px;
          margin-bottom: 6px;
          margin-right: 25px;
          display: inline-block;
        }

        button {
          font-size: 16px;
        }
      }

      .fidelity-search-bar {
        margin-right: 25px;
        font-size: 14px;
        .search-bar-label {
          font-weight: bold;
          margin-bottom: 5px;
        }
        width: 300px;
      }
    }

    > .content {
      z-index: 1002;
      position: relative;
      box-sizing: border-box;
      display: flex;
      align-items: stretch;

      .criteria-menu-container,
      .landing-page-container,
      .results-view {
        display: inline-block;
        vertical-align: top;
      }

      .criteria-menu {
        &.collapsed ~ .landing-page-container,
        &.collapsed ~ .results-view {
          width: calc(100% - #{$criteria-menu-closed-width});
        }
      }
      .landing-page-container {
        width: calc(100% - #{$criteria-menu-width});
      }
      .results-view {
        width: calc(100% - #{$criteria-menu-width});
      }
    }
  }

  .disclaimers {
    border-top: 1px solid $medium-border-gray;
  }
}

@media (max-width: $large-screen-width) {
  .evaluator {
    main {
      > .content {
        div.results-view,
        div.landing-page-container {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  .evaluator {
    main {
      padding-left: 0px;
      > .content {
        display: block;
        .criteria-menu,
        .landing-page-container {
          display: block;
        }
        div.landing-page-container,
        div.results-view {
          width: 100%;
        }
        .criteria-menu.collapsed ~ .results-view,
        .criteria-menu.collapsed ~ .landing-page-container {
          width: 100%;
        }
      }
    }
  }
}
