.fund-type-interface {
  background-color: $criteria-menu-grey;
  overflow: auto;
  max-height: 350px;

  .checkbox-filter-label {
    width: 7%;
  }

  ul.risk-filters {
    list-style: none;
    padding: 0;
    margin: 0;

    .checkbox-match-details {
      width: 25%;
    }

    .risk-icon {
      display: table-cell;
    }

    .risk-icon-legend {
      font-size: 9px;
      text-transform: uppercase;
      &:last-of-type {
        padding-left: 23px;
      }
    }

    .risk-icon-text {
      display: table-cell;
      font-weight: bold;
      padding-left: 5px;
      padding-top: 5px;
    }
  }
}
