#take-action-menu {
  min-height: 45px;
  padding: 10px 0 0 20px;
  border-right: 1px solid $medium-border-gray;
  position: relative;
  .fund-selections-container {
    position: relative;
    display: inline-block;
    vertical-align: bottom;
    margin-left: 10px;

    #num-funds-selected-container {
      .num-funds-selected {
        margin: 0;
        display: inline-block;
        padding-left: 20px;
        text-align: right;
      }
      .show-funds-selected {
        display: inline-block;
      }
    }
    line-height: 30px;
  }
  .results-view-action-menu {
    display: inline-block;
    button {
      @include action-button(
        $quick-criteria-menu-light-green,
        $quick-criteria-menu-green,
        $quick-criteria-menu-dark-green
      );
      margin-left: 10px;
      &.disabled {
        @include action-button($disabled-link, $disabled-link, $disabled-link);
        cursor: not-allowed;
        margin-left: 10px;
      }
    }
  }
  .compare-view-action-menu {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    button.back-to-research-results {
      @include action-button(
        $quick-criteria-menu-light-green,
        $quick-criteria-menu-green,
        $quick-criteria-menu-dark-green
      );
    }
    button.help-me-read-this-table {
      margin-right: 2%;
      img {
        vertical-align: baseline;
      }
    }
  }
  > button.help-me-read-this-table {
    position: absolute;
    line-height: 30px;
    right: 20px;
    img {
      vertical-align: baseline;
    }
  }
}
