cq-context:not([crosssection-active]) .toggle-options {
  display: inherit;
}
cq-context {
  background-color: transparent;
}

cq-show-range {
  left: 0px;
  float: none;
  div.active {
    font-weight: 700;
  }
}

.ciq-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  .header-text {
    font-size: 18px;
    font-weight: 700;
    margin-right: 1em;
  }
  .as-of-date {
    color: #666;
    font-size: 11px;
  }

  cq-toggle.tableview-ui {
    margin-left: auto;
    display: flex;
    align-items: center;
    height: auto;
    cq-text {
      margin-right: 1em;
      font-size: 14px;
      color: black;
      font-family: Fidelity Sans, Helvetica, Arial, sans;
      font-style: normal;
      font-weight: 400;
    }
    .switch {
      width: auto;
      height: auto;
      padding: 4px;
      border: 1px solid #7f7f7f;
      border-radius: 16px;
      background-color: transparent;
      transition: background-color 0.25s;
      &::before {
        content: '';
        display: block;
        background-color: #bbb;
        border-radius: 100%;
        width: 16px;
        height: 16px;
        margin-right: 16px;
        margin-left: 0px;
        transition: 0.25s;
      }
    }
    &:hover {
      cq-text {
        border: none;
      }
      .switch {
        box-shadow: 0 0 0 2px #7f7f7f;
        border: 1px solid #7f7f7f;
      }
    }
    &.active {
      border-bottom: none;
      .switch {
        background-color: #356f95;
        border: 1px solid #356f95;
        &::before {
          background-color: white;
          margin-left: 16px;
          margin-right: 0px;
        }
      }
    }
  }
}

.ciq-chart-area {
  .ciq-data-table-container {
    padding: 0;
    .ciq-data-table-toolbar,
    .ciq-data-table-wrapper tr {
      font-size: 12px;
      color: black;
      font-family: Fidelity Sans, Helvetica, Arial, sans;
      font-style: normal;
      font-weight: 400;
    }
    .ciq-data-table-toolbar {
      display: flex;
      align-items: center;
      :last-child {
        margin-left: auto;
      }
      .ciq-data-table-title {
        display: none;
        margin: 0;
        margin-right: 12px;
        font-size: 1.6rem;
        font-weight: 800;
      }
      .additionalColumns {
        display: none;
      }
      button {
        margin: 8px 4px;
        padding: 0 1em;
        line-height: 2.2em;
        color: #368727;
        border: 1px solid #368727;
        white-space: nowrap;
        border-radius: 3px;
        background-color: transparent;
      }
    }
    td,
    th {
      border: none;
      padding: 4px;
    }
    .ciq-data-table-wrapper {
      table {
        tr {
          border-top: 1px solid #ccc;
          td:nth-child(2),
          th:nth-child(2) {
            display: none;
          }
        }
        thead {
          background-color: #eee;
        }
        tbody {
          tr {
            background-color: white;
            &:nth-child(2n) {
              background-color: #fafafa;
            }
          }
        }
      }
    }
  }
}

.ciq-footer {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 0;
  cq-show-range {
    position: relative;
    float: none;
  }
  .range-menu {
    display: flex;
    align-items: center;
  }
  .frequency {
    margin-left: 48px;
  }
  .range-menu,
  .frequency {
    label {
      font-weight: 500;
      margin-right: 1em;
    }
  }
}

.ciq-chart {
  box-shadow: none;
  -webkit-box-shadow: none;
}

div.rm-full-scn {
  margin-left: 50px;
  margin-top: 30px;
}

html:not([ciq-last-interaction=touch]) cq-study-legend[cq-hovershow],
html:not([ciq-last-interaction=touch]) .stx-holder:hover cq-study-legend[cq-hovershow] {
  height:auto;
  max-width: 100%;
  width: 100%;
  margin: 0;
  &:not(.ciq-active) > :not(cq-marker-label) {
    width: 100%;
  }
  cq-marker-label {
    display: none
  }
  cq-comparison {
    cq-comparison-key {
      display: flex;
      flex-flow: row wrap;
      cq-comparison-item {
        font-size: 11px;
      }
    }
    cq-comparison-label {
      margin-left: 0.5em;
      width: auto;
    }
  }
}