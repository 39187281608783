.criteria-sub-group {
  .criteria-sub-group-header {
    display: table;
    height: $criteria-menu-item-height;
    width: 100%;

    &:last-child {
      .criteria-sub-group-spacer {
        border-bottom: $criteria-menu-border;
      }
    }

    .criteria-sub-group-spacer,
    .criteria-sub-group-content{
      display: table-cell;
      vertical-align: middle;
    }

    .criteria-sub-group-spacer {
      width: 13%;
      box-sizing: border-box;

      background-color: $criteria-menu-grey-darker;
      border-right: $criteria-menu-border;
    }

    .criteria-sub-group-content {
      width: 87%;
      box-sizing: border-box;

      background-color: $criteria-menu-grey;
      border-bottom: $criteria-menu-border;
      padding: 0px 10px;

      a {
        display: inline-block;
        vertical-align: middle;
        width: 90%;
      }

      img {
        cursor: pointer;
      }
    }
  }

  ul {
    display: table;
    width: 100%;
    margin: 0;
    padding: 0;

    &.off {
      display: none;
    }
  }
}
