.nested-fund-strategies {
  position: relative;

  .arrow-left-icon {
    position: absolute;
    top: 5px;
    left: -20px;
    cursor: pointer;
  }

  .grey-x-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  h3 {
    font-size: 18px;
    margin: 0;
    padding: 0;
  }
  .nested-strategies {
    margin-top: 30px;
    display: flex;
    flex-flow: row wrap;

    .nested-fund-strategy {
      cursor: pointer;
      box-shadow: 0px 2px 3px 0px rgba(102, 102, 102, 0.5);
      box-sizing: border-box;
      width: 30%;
      margin-right: 1.5%;
      margin-bottom: 20px;
      border: solid 1px $strategies-border-grey;
      border-radius: 8px;
      padding: 10px 15px;

      .parent-strategy {
        color: $dark-grey;
      }

      .main-content {
        padding: 10px 0 20px 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-start;

        .strategy-name {
          font-weight: bold;
          font-size: 16px;
        }
        img {
          height: 50px;
          width: 50px;
        }
      }

      .short-description {
        padding-top: 5px;
        color: $dark-grey;
        border-top: solid 1px $strategies-border-grey;
      }
    }
  }
}

@media (max-width: $large-screen-width) {
  .nested-fund-strategies {
    .nested-strategies {
      .nested-fund-strategy {
        width: 48%;
      }
    }
  }
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  .nested-fund-strategies {
    .arrow-left-icon {
      left: 0px;
    }

    h3 {
      margin-left: 15px;
    }
    .nested-strategies {
      .nested-fund-strategy {
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }
}
