.add-symbol-container {
  display: inline-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 5px 0;
  width: 100%;
  border-bottom: 1px solid $light-border-color;

  & > div {
    &:last-of-type {
    }
  }
  .input-symbol-container {
    input[type='text'] {
      border-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: solid 1px #bbb;
      height: 30px;
      padding-left: 5px;
      font-size: 14px;
      font-weight: normal;
      &.error {
        border: 2px solid $negative;
      }
    }
  }
  .add-symbol-button-container {
    .add-symbol-button {
      font-size: 12px;
      padding: 0 10px;
      font-weight: normal;
      height: 30px;
      border: solid 1px $action-button-blue;
      color: #fff;
      padding: 0 14px;
      border-left: none;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      background: $action-button-blue;
    }
  }
  .look-up-symbol-container {
    align-self: center;
    margin-left: 15px;
    margin-right: 15px;
    .look-up-symbol {
      font-weight: normal;
      font-size: 13px;
    }
  }
  .error-container {
    width: 100%;
    margin-top: 10px;
    white-space: normal;
    color: $negative;
    display: flex;
    .error-icon {
      height: 15px;
      width: 15px;
    }
    .error-text {
      padding: 0 0 0 10px;
      align-self: center;
      .look-up-symbol {
        padding: 0;
      }
    }
  }
}
