.results-view {
  margin-right: 20px;
  border-bottom: $criteria-menu-border;
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  .results-view {
    margin-right: 0px;
  }
}
