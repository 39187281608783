.disabled-overlay {
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(225, 225, 225, 0.8);
    z-index: 5;
    cursor: not-allowed;
  }
}
