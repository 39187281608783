.fund-family-subheader {
  display: table;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: $criteria-menu-border;

  * {
    display: table-cell;
    vertical-align: middle;
  }

  input[type='checkbox'] {
    display: none;
  }

  .checkbox-input img {
    cursor: pointer;
  }
}
