.inactive-criterion {
  display: table;
  height: $criteria-menu-item-height;
  width: 100%;
  border-bottom: $criteria-menu-border;

  &.top-level-criterion {
    .inactive-criterion-content {
      padding-left: 15px;
    }
  }

  .inactive-criterion-spacer,
  .inactive-criterion-content {
    display: table-cell;
    vertical-align: middle;
  }

  .inactive-criterion-spacer {
    width: 13%;
    box-sizing: border-box;

    background-color: $criteria-menu-grey-darker;
    border-right: $criteria-menu-border;
  }

  .inactive-criterion-content {
    width: 87%;
    box-sizing: border-box;

    background-color: $criteria-menu-grey;
    padding: 0px 10px;

    a,
    img {
      display: inline-block;
      vertical-align: middle;
    }

    button {
      font-weight: bold;
      color: #0f57c2;
      border: none;
      background: none;
    }

    img {
      margin-right: 8px;
      &.leaficon {
        height: 24px;
      }
    }

    .inactive-criterion-help-button {
      margin-left: 5px;
      width: 14px;
      height: 14px;
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      cursor: pointer;
      img {
        vertical-align: text-top;
      }
    }

    .dollarSignIcon {
      color: red;
      margin-left: 5px;
      margin-right: 8px;
      font-size: 13px;
      font-weight: 400;
    }
  }
}
