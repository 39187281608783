.results-card-item-group {
  padding: 10px 12px;
  border-top: solid 1px $results-card-border;

  h6 {
    font-size: 13px;
    margin: 0;
    padding-bottom: 10px;
  }

  .ntf {
    font-size: 14px;

    .label {
      vertical-align: middle;
      padding-left: 10px;
    }
  }

  .results-card-item-group-row {
    font-size: 14px;
    padding: 2px 0px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    border-bottom: solid 1px $results-card-border;
    @include risk-icon-container($width:90px);

    &:last-of-type {
      border-bottom: none;
    }

    div.value {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}