.quick-criteria-key-criteria {
  border-right: 1.5px solid #cccccc;
  margin-left: 10px;
  margin-right: 10px;

  label {
    font-size: 16px;
  }

  .large-header {
    @extend %quick-criteria-large-header;
  }

  li {
    padding: 7px;
  }

  ul.top-section {
    li {
      margin-top: 12px;
    }

    list-style: none;
    padding: 0;
    margin: 0px;

    .quick-criteria-checkbox-filter {
      display: table;
      table-layout: fixed;

      .icons {
        width: 40px;
      }
    }

    .item {
      .label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .mgmt-approach {
    margin-top: 15px;
    white-space: nowrap;

    .small-header {
      @extend %quick-criteria-small-header;
      margin-bottom: 10px;
    }

    label,
    input {
      cursor: pointer;
    }

    input {
      vertical-align: top;
      margin-right: 5px;
    }

    input:not(:first-of-type) {
      margin-left: 10%;
    }
  }
}

.sustainable-attributes-fidelity img.icon{
  height: 23px;
};

@media (max-width: $large-screen-width) {
  .quick-criteria-key-criteria {
    margin-left: 0px;
    border-right: none;
    div.large-header {
      margin-top: 25px;
    }
  }
}
