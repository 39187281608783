.pagination-container-bottom {
  padding: 10px 20px 20px 0;
  margin-left: 20px;
  border-right: 1px solid $medium-border-gray;
  & > div {
    display: inline-block;
    width: 50%;
  }
  .pagination-controls-container {
    text-align: left;

    ul.pagination-controls {
      list-style: none;
      margin: 0;
      padding: 0;
      & > li {
        display: inline-block;
      }
      li:last-of-type {
        border: none;
      }
      .control-item {
        border-right: 1px solid $medium-border-gray;
        padding: 0 5px;
        color: $link-color;
        cursor: pointer;
        &.active {
          color: $black;
          font-weight: bold;
          cursor: default;
        }
        &.disabled {
          color: $disabled-link;
          cursor: default;
        }
      }
    }
  }
  .num-results {
    text-align: right;
    font-weight: bold;
    color: $breadcrumbs-grey;
  }

  &.card {
    background-color: $results-card-grey;
    margin-left: 0px;
    border-right: none;
    border-bottom: none;

    .pagination-controls-container {
      border-right: none;
      display: block;
      text-align: center;
      width: 100%;
    }

    .num-results {
      margin-top: 10px;
      text-align: center;
      display: block;
      width: 100%;
    }
  }
}

.pagination-container-top {
	padding: 0;
	margin-left: 20px;
	border-right: 1px solid $medium-border-gray;
	height: 5px;
	& > div {
	  display: inline-block;
	  width: 50%;
	}
	.num-results {
	  text-align: right;
	  font-weight: bold;
	  color: $breadcrumbs-grey;
	  position:absolute;
	  right: 27px;
	}
  
	&.card {
	  background-color: $results-card-grey;
	  margin-left: 0px;
	  border-right: none;
	  border-bottom: none;
 
	  .num-results {
		margin-top: 10px;
		text-align: center;
		display: block;
		width: 100%;
	  }
	}
  }
