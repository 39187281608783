.mobile-header {
  position: relative;
  box-shadow: 0px 2px 3px 0px rgba(102, 102, 102, 0.5);
  z-index: 1002;

  .top-level {
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: solid 1px $light-border-color;
    background-color: $mobile-header-background-grey;

    h1 {
      font-size: 20px;
    }

    .dropdown-arrow {
      margin-right: 30px;

      &:after {
        @extend %arrow-icon;
        border-width: 9px 6px;
      }
      &.up:after {
        margin-top: -13px;
        border-bottom-color: $dark-grey;
      }

      &.down:after {
        margin-top: -4px;
        border-top-color: $dark-grey;
      }
    }
  }

  ul.dropdown-items {
    background: $white;
    z-index: 11;
    width: 100%;
    position: absolute;
    list-style-type: none;
    margin: 0;
    padding: 0;
    box-shadow: 0px 2px 3px 0px rgba(102, 102, 102, 0.5);

    li {
      border-bottom: solid 1px $light-border-color;
      padding: 15px;

      button {
        font-size: 16px;
        font-weight: bold;
        outline: none;
      }

      &.new-screen,
      &.nav-menu-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        cursor: pointer;
        img {
          width: 20px;
        }
      }

      &.fidelity-search {
        background-color: $mobile-header-background-grey;

        div:first-of-type {
          font-weight: bold;
          font-size: 16px;
          padding-bottom: 5px;
        }
      }
    }
  }
}

.mobile-header-z-index-higher{
  z-index: 1070;
}
