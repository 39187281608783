$transaction-width:500px;

.quick-criteria-results-footer {
  > div:first-of-type {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
  }

  .center-results {
    justify-content: center;
  }

  .right-results {
    justify-content: flex-end;

    .as-link {
      display: block;
      margin: 0 auto;
      margin-top: 20px;
    }
  }

  .match-count {
    color: $quick-criteria-menu-green;
    font-weight: bold;
    font-size: 28px;
    margin-right: 10px;
  }

  button.as-link {
    outline: none;
    margin: 0 15px;
    color: #346e94;
    font-size: 16px;
  }

  button.action-button {
    width: 240px;

    @include action-button(
      $quick-criteria-menu-light-green,
      $quick-criteria-menu-green,
      $quick-criteria-menu-dark-green
    );

    &.disabled {
      @include action-button($disabled-link, $disabled-link, $disabled-link);
      cursor: initial;
      margin-left: 15px;
      height: 36px;
      font-size: 18px;
      width: 298px;
    }
    margin-left: 15px;
    width: 298px;
    font-size: 18px;
    height: 36px;
  }

  button.action-button {
    margin-top: 25px;
  }

  .transaction-fee-footer{
    .quick-criteria-transaction-fee{
      max-width: $transaction-width;
      margin-top:25px;
      .transaction-fee-checkboxes{
        padding: 0;
        margin: 0;
        
        fieldset {
          border: none;
          margin: 0;
          padding: 0;

          legend {
            float: left;
            padding: 15px 20px 0 0;
            margin-top: -3px;
          }
      }
        .large-header{
          display: inline-block;
          margin-right: 20px;
          span{
            float:left;
            margin-top:-20px;
            font-size:16px;
          }
        }
        .first{
          display: inline-block;
          ul{
            padding:0;
            margin:2px;
          }
        }

        li{
          display: inline-block;
          margin-right:25px;
          font-size: 15px;
        }
      }
      .message{
        padding: 0;
        margin: 0;
        margin-top:8px;
        height: 70px;
        span{
          font-size: 14px;
          line-height: 22px;
          .add-link-here-div{
            display: inline-block;
            .add-link-here{
              color:red;
              font-size: 14px;
            }
          }
        }

      }
    }

  }

  .view-funds-clear-all-container {
    text-align: center;
    height: 150px;
   }
  .clear-all {
    margin: auto auto auto 15px;
    padding-top: 5px;
  }
  
  .dollarSignIcon{
    color: red;
    padding-left: 5px;
  }
}

@media (max-width: $large-screen-width) {
  .quick-criteria-results-footer {
    margin-top: 10px;
    > div:first-of-type {
      display: block;
      flex-flow: column nowrap;
      justify-content: flex-end;
      align-items: center;
      * {
        font-size:16px;
      }
    }
  }
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  .quick-criteria-results-footer {
    margin-top: 10px;

    .center-results .as-link {
      display: none;
    }

    > div:first-of-type {
      display: block;
      flex-flow: column nowrap;
      justify-content: flex-end;
      align-items: center;
      * {
        font-size:16px;
      }
     
      > span,
      > button {
        margin-bottom: 5px;
      }
      span:first-child {
        font-size: 35px;
      }
      span:not(first-child),
      span.all-available-funds {
        font-size: 15px;
      }
      .match-count {
        margin-right: 0;
      }
      button:last-child {
        height: 36px;
        font-size: 18px;
        width: 298px;
        margin-left: 0;
        &.disabled {
          margin-bottom: 5px;
          height: 36px;
          font-size: 18px;
          width: 298px;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .quick-criteria-results-footer {
    font-size:16px;
    > div:first-of-type {
      display: block;
    }
    .right-results,
    .center-results {
      > div {
        width: 100%;
      }
    }
    button.action-button {
      width: 100%;
    }
  }
}
