@mixin action-button($top-color, $bottom-color, $border) {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  font-family: 'Fidelity Sans', Helvetica, Arial, sans-serif;
  font-weight: 600;
  height: 30px;
  margin: 0;
  padding: 0 12px;
  text-decoration: none;
  vertical-align: middle;
  background-color: $bottom-color;
  background-image: -webkit-gradient(
    linear,
    50% 0,
    50% 50%,
    color-stop(0%, $top-color),
    color-stop(100%, $bottom-color)
  );
  background-image: -moz-linear-gradient(top, $top-color, $bottom-color 50%);
  background-image: -ms-linear-gradient(top, $top-color, $bottom-color 50%);
  background-image: -o-linear-gradient(top, $top-color, $bottom-color 50%);
  background-image: linear-gradient(top, $top-color, $bottom-color 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(
      GradientType=0,
      startColorstr=$top-color,
      endColorstr=$bottom-color
    );
  -ms-filter: 'progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=$top-color, endColorstr=$bottom-color)';
  border: 1px solid $border;
  -webkit-box-shadow: 1px 1px 2px #ccc;
  -moz-box-shadow: 1px 1px 2px #ccc;
  box-shadow: 1px 1px 2px #ccc;
  color: #fff;
  font-size: 14px;
  text-shadow: 1px 1px 4px $border;
}
@mixin chevron($border-color, $background-color, $size) {
  z-index: 1;
  position: absolute;
  content: ' ';
  display: block;
  width: $size;
  height: $size;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  border-bottom: solid 1px $border-color;
  border-right: solid 1px $border-color;
  background: $background-color;
  // right: 48%;
  // bottom: -16px;
}
@mixin box-scale-item($color, $size) {
  height: $size;
  width: $size;
  display: inline-block;
  background-color: $color;
  margin-left: 2px;
}
@mixin risk-icon-container($width: 100px, $font-size: 8px) {
  .risk-rating-container {
    width: $width;
    margin: auto;
    margin-bottom: calc(#{$font-size} + 2px);
    position: relative;
    .result-risk {
      width: $width;
      height: 10px;
      @extend %rainbow-bg;
    }
    .risk-rating-text {
      font-size: $font-size;
      font-weight: 600;
      color: $text-soft;
      &.right,
      &.left {
        position: absolute;
        margin-top: 2px;
        display: inline-block;
      }
      &.right {
        right: 0;
      }
      &.left {
        left: 0;
      }
    }
  }
}
