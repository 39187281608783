.multi-select-range-inputs {
  border-top: $criteria-menu-border;
  background-color: $white;

  form {
    display: flex;
    flex-flow: row nowrap;
    padding: 10px 5px;
    align-items: center;

    span {
      margin-left: 10px;
    }

    input[type='submit'] {
      @include action-button(
        $quick-criteria-menu-light-green,
        $quick-criteria-menu-green,
        $quick-criteria-menu-dark-green
      );
      height: 25px;
      margin-left: 30px;
    }

    &.error {
      .multi-select-text-input input {
        border: solid 2px $error;
      }
    }
  }

  .multi-select-text-input {
    margin-left: 10px;

    input {
      height: 25px;
      width: 90px;
      border-radius: 5px;
      text-align: right;
      padding-right: 5px;

      border: solid 2px $criteria-menu-border;
    }
  }

  .error-message {
    margin-left: 12px;
    padding-bottom: 5px;
    color: $error;

    img {
      margin-right: 5px;
    }
  }
}
