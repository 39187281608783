.action-checkbox-container {
  .action-checkbox {
    position: absolute;
    left: -99999px;
  }
  label {
    cursor: pointer;
    display: block;
    background-repeat: no-repeat;
    margin: auto;
  }
  label.x-icon {
    background-image: url(../media/images/grey_x.png);
    height: 12px;
    width: 12px;
    background-size: 12px 12px;
  }
  label.checkbox-icon {
    background-image: url(../media/images/checkbox.png);
    height: 18px;
    width: 18px;
    background-size: 18px 18px;
  }
  input[type='checkbox']:checked {
    & + label.checkbox-icon {
      background-image: url(../media/images/checkbox_checked.png);
    }
  }
  input[type='checkbox']:focus {
    & + label {
      outline: rgb(59, 153, 252) auto 5px;
    }
  }
}
