.nested-inactive-criterion {
  display: table;
  height: $criteria-menu-item-height;
  width: 100%;

  .nested-inactive-criterion-spacer,
  .nested-inactive-criterion-content {
    display: table-cell;
    vertical-align: middle;
  }

  .nested-inactive-criterion-spacer {
    width: 13%;
    box-sizing: border-box;

    background-color: $criteria-menu-grey-darker;
    border-right: $criteria-menu-border;
  }

  .nested-inactive-criterion-content {
    width: 87%;
    box-sizing: border-box;

    background-color: $criteria-menu-nested-item;
    border-bottom: $criteria-menu-border;
    padding: 0px 10px;

    a,
    img {
      display: inline-block;
      vertical-align: middle;
    }

    img {
      margin-right: 8px;
    }
  }
}
