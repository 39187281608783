.results-card-item,
.no-results-card {
  box-sizing: border-box;
  background-color: white;
  margin: 10px 10px;
  border: solid 1px $results-card-border;
  box-shadow: 0px 2px 3px 0px rgba(102, 102, 102, 0.5);

  &.fidelity-light-green {
    background-color: $fidelity-light-green;
  }

  &:first-of-type {
    margin-top: 0px;
  }
}

.no-results-card {
  font-weight: bold;
  .no-results-text {
    padding: 10px;
  }
}
