.view-all-footer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: $view-all-footer-height;
  z-index: 999999;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: white;
  border-top: solid 1px $light-border-color;

  * {
    display: inline-block;
  }

  .view-all-cancel {
    padding-right: 26px;

    span {
      color: $link-color;

      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .view-all-apply-criteria button {
    @include action-button($action-button-light-blue, $action-button-blue, $action-button-dark-blue);

    &.disabled {
      @include action-button($disabled-link, $disabled-link, $disabled-link);
    }
  }
}
