.quick-criteria-menu {
  box-sizing: border-box;
  padding: 15px;
  font-family: 'Fidelity Sans', Helvetica, Arial, sans-serif;
  color: #000;

  .checkboxLabel {
    font-size: 15px;
    padding-left: 5px;
  }

  .all-quick-criteria {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    .quick-criteria-fund-type {
      width: 34%;
      padding-right: 3%;
      padding-left: 2%;
    }

    .quick-criteria-key-criteria {
      width: 33%;
      padding-right: 3%;
      padding-left: 3%;
    }

    .quick-criteria-morningstar-ratings {
      width: 33%;
      padding-left: 3%;
    }
  }
}

@media (max-width: $large-screen-width) {
  .quick-criteria-menu {
    .all-quick-criteria {
      flex-flow: row wrap;

      .quick-criteria-fund-type,
      .quick-criteria-key-criteria,
      .quick-criteria-morningstar-ratings {
        margin-top: 15px;
        width: 100%;
        padding: 0;
      }
    }
  }
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  .quick-criteria-menu {
    background: $criteria-menu-grey;
  }
}
