.quick-criteria-menu {
  .quick-criteria-asset-class-label-wrap {
    position: relative;
    padding: 7px 8px 5px 10px;
    height: 38px;
    background-color: #efefef;
  }
  .all-asset-class-container {
    margin-left: 10px;
    margin-bottom: 17px;
    height:45px;
  }
  .quick-criteria-class-popup {
    position: absolute;
    padding: 5px 15px;
    top: 50px;
    background: white;
    border-radius: 5px;
    border: solid 1px $asset-class-dropdown-border;
    z-index: 9;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08), 0 1px 6px rgba(0, 0, 0, 0.12);
    color: #000;
    min-width: 340px;

    .quick-criteria-checkbox-filter {
      display: block;
      margin: 0 0 17px 0;
    }
    .quick-criteria-checkbox-filter > div:first-of-type {
      display: inline-block;
    }

    .fidelity-green {
      color: $fidelity-green;
    }

    .asset-class-wrap {
      padding: 0 0 4px 0;
      margin-bottom: 10px;
      .quick-criteria-asset-class-label-wrap {
        padding: 7px 8px 5px 10px;
        height: 67px;
        background-color: #efefef;
        .asset-class-header {
          width:165px;
          display: flex;
          flex-flow: column;
          cursor: pointer;
          position: absolute;
          .quick-criteria-class-checkbox {
            //width:100%;
            display: table;
            cursor: pointer;
            float: left;
            div {
              display: table-cell;
              vertical-align: middle;
            }

            label {
              cursor: pointer;
            }
          }
          .selected-categories-count {
            width: 100%;
            font-size: 12px;
            margin-left: 27px;
            margin-top: 4px;
            cursor: pointer;
          }
        }
        .risk-image-div {
          position: absolute;
          right:0;
          width:145px;

          .risk-image {
            width:138px;
            cursor: pointer;
            float:left;
            margin-top:-6px;
            img {
              z-index: 10;
              width:135px;
              max-width:135px;
              max-height: 64px;
            }
          }

          .dropDownButton {
            height: 100%;
            cursor: pointer;
            width: 25px;
            float: right;
            .caret {
              position: absolute;
              right: 0;
              top: 0;
              width: 25px;
              height: 37px;
              cursor: pointer;
              background: url('../media/images/dropdown-chevrons.png') white top center;

              &.down {
                background-position: bottom center;
              }
            }
          }
        }
      }
      .all-asset-classes-label{
        font-weight: 700;
      }
    }

    .risk-distribution {
      width: 100%;
      .risk-distribution-container {
        position: absolute;
        right:0;
        width:155px;
        span {
          font-size: 11px;
          font-weight: 700;
        }
      }
    }

    .quick-criteria-class-popup-categories {
      display: none;
      padding: 0px 5px;
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;
      margin: 5px;

      .quick-criteria-class-checkbox {
        width: 100%;
        > div {
          display: inline-flex;
          align-items: center;
          width: 100%;
          height: 38px;
        }
        .checkbox-input {
          float: left;
        }
        label {
          cursor: pointer;
        }
      }

      .checkboxLabel {
        font-size: 15px;
        line-height: 19px;
      }
    }

    .collapse-all {
      position: absolute;
      right: 30px;
      top: 70px;
    }
  }

  .asset-class-container {
    max-height: 480px;
    overflow-y: scroll;
  }
}

.quick-criteria-menu {
  .quick-criteria-asset-class-label-wrap {
    position: relative;
    .caret {
      position: absolute;
      right: 0;
      top: 0px;
      width: 37px;
      height: 37px;
      cursor: pointer;
      background: url('../media/images/dropdown-chevrons.png') white top center;

      &.down {
        background-position: bottom center;
      }
    }
  }
}

#asset-class-search-form {
  margin: 0 0 20px 0;

  input {
    padding: 12px 12px 12px 42px;
    border: none;
    width: 100%;
    border-bottom: solid 1px $popup-border-grey-darker;
    background: url('../media/images/search-icon.png') white left center no-repeat;
    font-size: 15px;
    line-height: 19px;
    color: black;
    outline: none;

    &::placeholder {
      color: black;
    }
  }
}

@media (max-width: 991px) {
  .modal-wrap > div:first-of-type {
    padding-right: 0;
  }
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  .search-legend {
    position: relative;
  }

  .quick-criteria-menu {
    .quick-criteria-class-popup {
      .asset-class-wrap {
        .label {
          font-size: 15px;
          line-height: 19px;
        }

        .quick-criteria-class-popup-categories {
          columns: 1;
          -webkit-columns: 1;
          -moz-columns: 1;
        }
      }
    }
  }
  .quick-criteria-asset-class-label-wrap {
    min-width: 285px;
    max-width: auto;
  }
  .asset-class-container > div:first-of-type {
    padding-right: 0;
  }
}
