.inactive-criteria-all,
.criteria-group-header {
  display: table;
  width: 100%;
  margin: 0;
  padding: 0;
}

.criteria-group {
  &:first-of-type {
    border-top: $criteria-menu-border;
  }

  .criteria-sub-group:last-child {
    &.collapsed {
      .criteria-sub-group-spacer {
        border-bottom: $criteria-menu-border;
      }
    }
    .nested-inactive-criterion:last-of-type {
      .nested-inactive-criterion-spacer {
        border-bottom: $criteria-menu-border;
      }
    }
  }
}
