.results-card-item-footer {
  width: 100%;
  height: 60px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  border-top: solid 1px $results-card-border;

  > div {
    display: table-cell;

    &.off {
      display: none;
    }
  }

  .details-expander-button {
    cursor: pointer;
    img {
      margin-bottom: 5px;
    }

    button {
      outline: none;
    }
  }

  .external-link-buttons {
    .research-button,
    .buy-button {
      display: inline-block;
      &.off {
        display: none;
      }
    }

    .research-button {
      button {
        @include action-button($fidelity-secondary-lighter, $fidelity-secondary-darker, $fidelity-secondary-border);
        height: 40px;
        color: black;
        font-weight: normal;
        margin-right: 10px;
      }
    }

    .buy-button {
      button {
        @include action-button(
          $quick-criteria-menu-light-green,
          $quick-criteria-menu-green,
          $quick-criteria-menu-dark-green
        );
        height: 40px;
      }
    }
  }
}
