.fund-family-interface {
  background-color: $criteria-menu-grey;

  .filter-list {
    height: 250px;
    overflow: auto;

    ul {
      padding: 0px;
      margin: 0px;
    }
  }
}
