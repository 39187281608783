.active-criterion {
  position: relative;

  .active-criterion-header,
  .checkbox-interface,
  .multi-select-interface,
  .radio-button-interface {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .checkbox-interface,
  .multi-select-interface,
  .radio-button-interface,
  .fund-family-interface,
  .fund-type-interface {
    border-top: $criteria-menu-border;
  }

  &:not(:last-of-type):after {
    @include chevron($criteria-menu-grey-darkest, $criteria-menu-grey, 12px);
    right: 36px;
    bottom: -6px;
  }
}
