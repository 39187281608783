.results-card-view {
  h4 {
    margin: 0px;
    font-size: 16px;
    padding-bottom: 6px;
    padding-left: 12px;
    padding-top: 10px;
  }
  button.as-link {
    padding-left: 12px;
    font-weight: normal;
    font-size: 14px;
    padding-bottom: 10px;

    &.off {
      display: none;
    }
  }

  background-color: $results-card-grey;
  padding-bottom: 10px;
}
