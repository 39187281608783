.download-results-button {
    margin: 0;
    border: none;
    background: none;
    color: #0f57c2;

    img {
        position: relative;
        top: -1px;
    }
}

.modal .modal-container > .modal-content.download-results-modal {
    max-height: 400px;
    width: 400px;
}
.download-results-modal {
    ul {
        padding: 0;
        margin: 0;
    }

    li {
        margin-bottom: 10px;
    }

    .checkbox-input {
        padding-right: 10px;
    }

    footer {
        width: 50%;
        margin: 0 auto;
    }

    .link-button {
        font-weight: 400;
        display: inline-block;
        margin-top: 10px;
        width: auto;
        border: none;
        background: none;
        color: #0f57c2;
        margin-right: 35px;

        &:hover {
            text-decoration: underline;
        }
    }

    .continue {
        width: 50%;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px;
        cursor: pointer;
        font-family: 'Fidelity Sans', Helvetica, Arial, sans-serif;
        font-weight: 600;
        height: 30px;
        margin: 0;
        padding: 0 12px;
        text-decoration: none;
        vertical-align: middle;
        background-color: #3f8700;
        background-image: -webkit-gradient(linear, 50% 0, 50% 50%, color-stop(0%, #7aac4e), color-stop(100%, #3f8700));
        background-image: -moz-linear-gradient(top, #7aac4e, #3f8700 50%);
        background-image: -ms-linear-gradient(top, #7aac4e, #3f8700 50%);
        background-image: -o-linear-gradient(top, #7aac4e, #3f8700 50%);
        background-image: linear-gradient(top, #7aac4e, #3f8700 50%);
        filter: progid:DXImageTransform.Microsoft.gradient( GradientType=0, startColorstr=$top-color, endColorstr=$bottom-color );
        -ms-filter: 'progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=$top-color, endColorstr=$bottom-color)';
        border: 1px solid #55842d;
        -webkit-box-shadow: 1px 1px 2px #ccc;
        -moz-box-shadow: 1px 1px 2px #ccc;
        box-shadow: 1px 1px 2px #ccc;
        color: #fff;
        font-size: 106.7%;
        text-shadow: 1px 1px 4px #55842d;
        float: right;

        &.disabled {
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px;
            cursor: pointer;
            display: inline-block;
            font-family: 'Fidelity Sans', Helvetica, Arial, sans-serif;
            font-weight: 600;
            height: 30px;
            margin: 0;
            padding: 0 12px;
            text-decoration: none;
            vertical-align: middle;
            background-color: #bdbabe;
            background-image: -webkit-gradient(linear, 50% 0, 50% 50%, color-stop(0%, #bdbabe), color-stop(100%, #bdbabe));
            background-image: -moz-linear-gradient(top, #bdbabe, #bdbabe 50%);
            background-image: -ms-linear-gradient(top, #bdbabe, #bdbabe 50%);
            background-image: -o-linear-gradient(top, #bdbabe, #bdbabe 50%);
            background-image: linear-gradient(top, #bdbabe, #bdbabe 50%);
            filter: progid:DXImageTransform.Microsoft.gradient( GradientType=0, startColorstr=$top-color, endColorstr=$bottom-color );
            -ms-filter: 'progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=$top-color, endColorstr=$bottom-color)';
            border: 1px solid #bdbabe;
            -webkit-box-shadow: 1px 1px 2px #ccc;
            -moz-box-shadow: 1px 1px 2px #ccc;
            box-shadow: 1px 1px 2px #ccc;
            color: #fff;
            font-size: 106.7%;
            text-shadow: 1px 1px 4px #bdbabe;
            cursor: not-allowed;
        }
    }
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) {
    .results-container .table-container .fidelity-results-table-title .right-title-items {
        float: none;
        margin-top: 10px;

        .as-link {
            padding-left: 0;
        }
    }
}