.view-all-header {
  height: $view-all-header-height;
  padding: 18px 15px 5px 16px;
  border-bottom: solid 1px $light-border-color;

  h2 {
    font-size: 24px;
    line-height: 24px;
    margin: 0;
  }

  .view-all-glossary-link {
    position: absolute;
    right: 40px;
    top: 30px;
    a {
      text-decoration: none;
      font-weight: normal;
    }
    a:hover {
      text-decoration: underline;
    }
  }

  .view-all-group-headers {
    list-style: none;
    padding-left: 0px;

    li {
      border-right: 1px solid #333;
      padding-right: 10px;
      margin-left: 10px;
      margin-bottom: 7px;
      display: inline-block;

      a {
        text-decoration: none;
        font-weight: normal;
      }
      a:hover {
        text-decoration: underline;
      }
    }

    li:last-of-type {
      border-right: none;
    }
  }
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  .view-all-header {
    height: 130px;
  }
}
