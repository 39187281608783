%quick-criteria-large-header {
  font-weight: bold;
  font-size: 16px;
  color: #000000;
}

%quick-criteria-small-header {
  font-size: 12px;
  color: #000000;
}

input[type='radio'] {
  height: 22px;
  width: 22px;
  vertical-align: middle;
}

input[type='radio']:after {
  width: 22px;
  height: 22px;
  border-radius: 100px;
  position: relative;
  content: '';
  display: inline-block;
  visibility: visible;
}

input[type='radio']:checked:after {
  width: 22px;
  height: 22px;
  border-radius: 100px;
  position: relative;
  background-color: #ffffff;
  border: 6px solid #6f9824;
  content: '';
  display: inline-block;
}

.disclosure-text {
  font-size: 12px;
  line-height: 1.45;
  font-weight: 400;
  color: #666;
  color: #000;
  float: left;
}

.disclosure-heading {
  font-size: 17px;
  font-weight: 700;
  color: #000;
  display: inline;
  position: relative;
  top: -3px;
}
