.strong {
  font-weight: bold;
}

.positive {
  color: $positive;
}

.negative {
  color: $negative;
}

.soft {
  font-size: 9px;
  color: $text-soft;
}

.text-as-link {
  cursor: pointer;
  color: $link-blue;
  &:hover {
    text-decoration: underline;
  }
}
