.fidelity-search-bar {
  .search-box {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    input[type='text'] {
      width: 80%;
      border-radius: 4px;
      border: solid 1px #bbb;
      height: 25px;
      padding-left: 5px;
      font-size: 12px;
      font-weight: normal;
    }

    input[type='submit'] {
      @include action-button(
        $quick-criteria-menu-light-green,
        $quick-criteria-menu-green,
        $quick-criteria-menu-dark-green
      );
      height: 25px;
    }
  }
}
