.view-all-criterion {
  display: table;
  padding: 5px;
  cursor: pointer;

  * {
    display: table-cell;
    vertical-align: middle;
  }

  span {
    padding-left: 10px;
  }
}
