.result-toggle-group {
  position: relative;
  .toggle-group-dropdown {
    font-weight: normal;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 3px;
    max-width: 75%;
    background-color: white;
    border: 1px solid $results-border-gray;
    z-index: 1;
	padding-inline-start: 0;
    .toggle-group-option {
      cursor: pointer;
      padding: 2px 0;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;

      &:hover {
        background-color: $criteria-menu-grey-darker;
        border-top: 1px solid $medium-border-gray;
        border-bottom: 1px solid $medium-border-gray;
        &:first-of-type {
          border-top: 1px solid transparent;
        }
        &:last-of-type {
          border-bottom: 1px solid transparent;
        }
      }
      &.active {
        background-color: $criteria-menu-grey-darkest;
      }
    }
    &.fixed {
      width: 55px;
      position: fixed;
      margin: 0;
    }
  }
  .dropdown-hidden{
	display: none;
  }
}
