#compare-chart-container {
  position: relative;
  padding: 10px;
  border-right: 1px solid $light-border-color;
  border-bottom: 1px solid $light-border-color;

  .chart-container {
    height: 450px;
    position: relative;
  }
}
