.quick-criteria-icon-filter {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-height: 40px;
  width: 120px;

  .icon-row {
    width: 100%;
    z-index: 1;

    > .icon-container {
      display: flex;
      flex-direction: column;
    }

    .icons {
      &.star-icons {
        margin-left: -2px;
      }
      .icon-container {
        display: inline-block;
        font-size: 10px;
        cursor: pointer;
        & * {
          pointer-events: none;
        }
      }

      .box {
        width: 19px;
        height: 19px;
        margin-left: 4px;
      }
    }
  }

  .quick-criteria-icon-filter-labels {
    display: flex;
    flex-flow: nowrap;
    justify-content: space-between;
  }

  .quick-criteria-icon-filter-labels,
  .ratings-text {
    font-size: 12px;
  }

  .ratings-text {
    color: $dark-grey;
    padding: 10px 0;
    min-width: 100%;
    height: 48px;
    min-height: 48px;
  }
}
