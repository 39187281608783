.select-all-subheader {
    min-height: 44px;
    height: 1px;
    width: 100%;
    display: table;
    background-color: #f9f9f9;
    border-top: solid 1px #dbdbdb;
    
  
    * {
      display: table-cell;
      vertical-align: middle;
    }
  
    input[type='checkbox'] {
      display: none;
    }
    .checkbox {
      margin-left: 15px;
      margin-right:15px;
    }
    .checkbox-input img {
      cursor: pointer;
    }
  }