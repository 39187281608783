.strategy-info {
  border-top: $criteria-menu-border;
  border-right: $criteria-menu-border;
  padding: 15px;

  &.collapsed {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .expander-toggle-button {
    cursor: pointer;
    width: auto;
    display: inline-block;

    button {
      margin-left: 5px;
    }
  }

  .strategy-description {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    &.off {
      display: none;
    }
    .strategy-description-text {
      margin-left: 3%;
      width: 80%;
      margin-right: 3%;
      font-size: 16px;
    }

    img {
      height: 100px;
      width: auto;
    }
  }
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  .strategy-info {
    border-top: none;
    border-bottom: $criteria-menu-border;
  }
}
