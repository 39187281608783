.popup-icon {
  border-radius: 100%;
  background-color: #0e57c2;
  width: 14px;
  height: 14px;
  display: block;
  color: white;
  font-size: 9pt;
  font-weight: bold;
  position: relative;
  span {
    position: absolute;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
