$regular-font-size: 12px;
$large-font-size: 16px;

$criteria-menu-height: 1100px;
$criteria-menu-item-height: 44px;
$criteria-menu-width: 320px;
$criteria-menu-closed-width: 36px;

$medium-screen-width: 768px;
$large-screen-width: 992px;
$extra-large-screen-width: 1200px;
$mobile-landscape-width: 812px;
