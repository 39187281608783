.results-card-item-header {
  width: 100%;

  .top-level-data {
    padding: 12px;
    padding-bottom: 5px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .fidelity-fund-icon,
    .fund-picks,
    .socially-responsible {
      padding-right: 5px;
    }
  }

  .name {
    font-size: 14px;

    .results-transaction-fee {
      margin-right: 4px;
      color: red;
    }

    .status-description-asterisks {
      display: inline-block;
      margin-right: 4px;
    }
  }

  .category {
    font-size: 14px;
    padding-top: 5px;
    padding-left: 12px;
    padding-bottom: 8px;
  }

  .overview-table-info {
    width: calc(100% - 24px); /* the -24px accounts for the margin */
    margin: 10px 12px;
    .overview-table-info-row {
      .label,
      .value {
        padding: 4px 0px;
      }

      .morningstar-overall {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;

        .star {
          font-size: 7px;
        }

        > span {
          font-size: 12px;
          margin-bottom: 4px;
          color: $black;
        }

        .star-group {
          display: flex;
          padding-right: 5px;
        }
      }
    }
  }

  .performance-overview {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: solid 1px $results-card-border;
    text-align: center;
    align-items: center;

    .ytd,
    .one-year,
    .three-year {
      font-size: 14px;

      > div:first-of-type {
        font-weight: bold;
      }

      > span.results-as-of-date {
        display: none; /* we have a hardcoded as of date for YTD Daily here */
      }
    }
  }
}
