.quick-criteria-checkbox-filter {
  display: table;
  cursor: pointer;

  div {
    display: table-cell;
    vertical-align: middle;
  }

  label {
    cursor: pointer;
  }
}

.star,
.box {
  cursor: pointer;
}
