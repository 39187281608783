.disclosures-container {
  font-family: Cambria, Georgia, Serif;
  font-size: 12px;
  line-height: 15px;
  color: #666;
  margin: 0 10px;

  p {
    padding: 0 8px 15px 8px;
    margin: 0;
  }

  .expand-collapse-link {
    padding: 0 8px 8px 8px;
    vertical-align: top;

    &:hover {
      text-decoration: none;
    }

    img {
      vertical-align: top;
    }

    span {
      padding-left: 8px;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .disclosures {
    padding-left: 20px;
  }
}
