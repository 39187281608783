.active-criterion-definition {
  display: table;
  height: 35px;
  width: 100%;
  box-sizing: border-box;
  background-color: $criteria-menu-grey;
  border-bottom: $criteria-menu-border;
  vertical-align: middle;

  a {
    @extend %criterion-item;
    padding-left: 10px;
    text-decoration: none;
    cursor: auto;

    * {
      cursor: pointer;
    }
  }
}
