%multi-select-connector-line {
  z-index: -999;
  display: block;
  content: ' ';
  position: absolute;
  width: 4px;
  background: $criteria-menu-grey-darkest;
  left: 50%;
  transform: translate(-50%, 0);
}

%checkbox-boundaries {
  z-index: 999;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  width: 16%;
}

.checkbox-filter {
  @extend %filter-styling;
  table-layout: fixed;

  &:last-of-type {
    border-bottom: none;
  }

  // .checkbox-input,
  .checkbox-filter-label,
  .checkbox-filter-icon,
  .checkbox-filter-children,
  .checkbox-match-details {
    @extend %criterion-item;
  }

  .checkbox-filter-spacer {
    cursor: initial;
    display: table-cell;
    vertical-align: middle;
    width: 39px;
    box-sizing: border-box;
    background-color: $criteria-menu-grey-darker;
    border-right: solid 1px #dbdbdb;
  }

  .checkbox-input {
    @extend %checkbox-boundaries;

    &.first {
      &::before {
        @extend %multi-select-connector-line;
        height: 50%;
        bottom: 0;
      }
    }

    &.middle {
      &::before {
        @extend %multi-select-connector-line;
        height: 100%;
        top: 0;
      }
    }

    &.last {
      &::before {
        @extend %multi-select-connector-line;
        height: 50%;
        top: 0;
      }
    }
  }

  .checkbox-filter-label,
  .checkbox-filter-icon {
    @extend %filter-label;
  }

  .checkbox-match-details {
    @extend %match-details;
    padding-right: 14px;

    .checkbox-cumulative-matches {
      @extend %match-details-cumulative;
    }
  }

  .star {
    font-size: 7px;
  }
}

// New Checkbox Styling

li.checkbox-input {
  list-style: none;
}

.checkbox-filter {
  &.checkbox-input {
    label {
      @extend %checkbox-boundaries;

      display: table-cell;
      vertical-align: middle;
    }
    .checkbox {
      margin-left: 15px;
    }
  }
}
.checkbox-input {
  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  input {
    visibility: hidden;
    position: absolute;
    width: 0;
    height: 0;
  }

  .checkbox-label {
    font-size: 15px;
    padding-left: 5px;
    word-break:break-word;
  }
  .checkbox-label2 {
    font-size: 15px;
    word-break:break-word;
  }

  .icons {
    padding-left: 5px;
    min-width: 28px;
    text-align: center;
  }

  .checkbox {
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;
    border: 1px solid;
    border-radius: 5px;

    display: block;
    stroke: white;
    stroke-width: 2;
    fill: none;
  }
  &.checked {
    .checkbox {
      background-color: #6f9824;
      border-color: #486417;
      &:not(.partial-check) {
        .dash {
          display: none;
        }
      }
      &.partial-check {
        .checkmark {
          display: none;
        }
      }
    }
  }
  &.unchecked {
    .checkbox {
      background-color: white;
      border-color: #bbb;
      path {
        display: none;
      }
    }
  }
}
