.expandable-checkbox-filter {
  border-right: $criteria-menu-border;

  .expandable-checkbox-filter-top-level {
    @extend %filter-styling;

    .expandable-checkbox-top-level-input {
      label {
        width: 16%;
        padding-left: 15px;
      }
      display: flex;
      align-items: center;
      height: 100%;
      .expandable-checkbox-match-details {
        margin-right: 0px;
        margin-left: auto;
      }
    }
  }

  &:last-of-type {
    .expandable-checkbox-filter-top-level {
      border-bottom: none;
    }
    .expandable-checkbox-nested-filters {
      .checkbox-filter:first-of-type {
        border-top: $criteria-menu-border;
      }
    }
  }

  .expandable-checkbox-input,
  .expandable-checkbox-label,
  .expandable-checkbox-match-details,
  .expandable-checkbox-top-level-input,
  .expander {
    @extend %criterion-item;
  }

  .expander {
    width: 13%;
    box-sizing: border-box;
    background-color: $criteria-menu-grey-lighter;
    border-right: $criteria-menu-border;
    position: relative;
    text-align: center;

    img {
      display: table-cell;
      vertical-align: middle;
      margin: auto;
      cursor: pointer;
    }
  }

  .expandable-checkbox-input {
    z-index: 999;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    width: 9%;
  }

  .expandable-checkbox-label {
    @extend %filter-label;
    width: 25%;
  }

  .expandable-checkbox-match-details {
    @extend %match-details;
    box-sizing: border-box;
    border-right: none;

    .expandable-checkbox-cumulative-matches {
      @extend %match-details-cumulative;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    .checkbox-filter-label {
      width: 49%;
    }

    li {
      border-bottom: none;

      .checkbox-input,
      .checkbox-filter-label,
      .checkbox-match-details {
        border-bottom: $criteria-menu-border;
      }

      .checkbox-filter-spacer {
        width: 39px;
      }

      &:last-of-type {
        .checkbox-filter-spacer {
          border-bottom: $criteria-menu-border;
        }
      }
    }
  }
}
