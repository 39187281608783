.fund-type-subheader {
  display: table;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  border-bottom: $criteria-menu-border;

  .fund-type-toggle {
    input,
    label {
      cursor: pointer;
    }
  }

  label {
    padding-left: 5px;

    &:first-of-type {
      padding-right: 20px;
    }
  }

  p {
    margin-bottom: 0px;
  }

  a {
    font-weight: normal;
  }
}
