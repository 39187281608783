.ways-to-invest {
  display: block;
  margin: 0 auto;
  padding: 15px 0 0;
  max-width: 820px;
  font-family: "Fidelity Sans", Helvetica, Arial, sans-serif;
  font-size: 14px;
  
  > p {
    font-size: 14px;
  }
  .row {
    margin-top: 30px;

    > a {
      display: table-cell;
    }
  }

  a, a:hover {
    text-decoration: none;
    color: #0f57c2;
  }

  .ways-to-invest-teaser {
    display: table-cell;
    vertical-align: top;
    padding: 5px 0 0 30px;

    a {
      display: block;
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 16px;
    }
  }

  img, img:hover {
    border: none;
    outline: none;
  }
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  .ways-to-invest {
    margin: 0 4%;

    .row {
      margin-top: 20px;
    }
  }
}