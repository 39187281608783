.results-title {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  border-top: $criteria-menu-border;
  border-right: $criteria-menu-border;
  background-color: $results-title-grey;
  padding: 10px 15px 15px 15px;

  h3 {
    margin: 0;
    margin-bottom: 5px;
    font-size: 20px;
  }

  .result-count-subtitle {
    color: $dark-grey;
    font-size: 13px;
    line-height: 18px;
  }

  .fidelity-search-bar {
    width: 50%;
    max-width: 300px;

    .search-bar-label {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  .results-title {
    background-color: $results-card-light-grey;
    border-bottom: $criteria-menu-border;
    padding: 0;
    width: 100%;
    padding-bottom: 10px;
    display: block;

    .title-and-result-count {
      width: 100%;

      h3 {
        font-size: 2.3em;
        font-weight: normal;
        text-align: center;
        margin: 15px 0;
      }

      .result-count-subtitle {
        box-sizing: border-box;
        padding: 0px 15px;
        font-size: 15px;
      }
    }
  }
}
