.quick-criteria-category-popup {
  position: absolute;
  top: 20px;
  background: white;
  border: solid 1px $popup-border-grey-darker;
  min-width: 45vw;
  z-index: 9;

  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08), 0 1px 6px rgba(0, 0, 0, 0.12);

  div.row > div {
    border-right: solid 1px $popup-border-grey-darker;

    &:last-of-type {
      border-right: none;
    }
  }

  .all-categories {
    border-bottom: solid 1px $popup-border-grey-darker;
  }

  .quick-criteria-checkbox-filter {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    table-layout: fixed;
    padding: 10px;
    border-bottom: solid 1px $popup-border-grey-darker;

    .zero-categories-message {
      margin-left: 20px;
      font-weight: bold;
    }

    &:last-of-type {
      border-bottom: none;
    }

    &.force-bottom-border {
      border-bottom: solid 1px $popup-border-grey-darker;
    }

    .checkbox-input {
      width: 30px;
    }

    .label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@media (max-width: $large-screen-width) {
  .quick-criteria-category-popup {
    .all-categories {
      border-bottom: none;
      background-color: $popup-grey;
      .quick-criteria-checkbox-filter {
        flex-flow: wrap;
      }
    }

    div.row > div {
      border-right: none;
    }

    .quick-criteria-checkbox-filter {
      &:last-of-type {
        border-bottom: solid 1px $popup-border-grey-darker;
      }
    }
  }
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  .quick-criteria-category-popup {
    min-width: 92vw;
    right: 0px;
  }
}
