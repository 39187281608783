@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  .pricing-model-related-info {
    width: auto !important;
    margin-top: 10px;
  }
}

.pricing-model-related-info {
  width: 50%;
  max-width: 300px;
  margin-left: 15px;

  .pricing-model-related-info-label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .pricing-model-related-info-link-list {
    list-style: none;
    li {
      margin-bottom: 3px;
      img {
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
		margin-right: 5px;
		height: 19px;
		padding: 2px;
		margin-bottom: 2px;
      }
    }
  }
}
