.fund-strategies {
  margin-top: 40px;
  margin-left: 15%;
  margin-right: 15%;

  p {
    font-size: 14px;
  }

  .fund-strategies-teaser {
    font-size: 16px;
  }

  .all-strategies {
    display: flex;
    flex-flow: row wrap;

    &.expanded {
      .fund-strategy {
        &:nth-of-type(n + 13) {
          border-bottom: none;
        }
      }
    }

    &.collapsed {
      .fund-strategy {
        &:nth-of-type(n + 5) {
          border-bottom: none;
        }
      }
    }

    .fund-strategy {
      cursor: pointer;
      box-sizing: border-box;
      width: 25%;
      padding-bottom: 25px;
      padding-top: 25px;
      border-right: solid 2px $strategies-border-grey;
      text-align: center;
      border-bottom: solid 2px $strategies-border-grey;

      &:nth-of-type(4n) {
        border-right: none;
      }

      button {
        margin: 0 auto;
        display: block;
      }

      img {
        margin-bottom: 10px;
        height: 100px;
        width: 100px;
      }
    }
  }

  .show-strategies-toggle {
    display: flex;
    justify-content: center;
    padding-top: 25px;
    font-size: 14px;
    img {
      margin-right: 20px;
    }
  }
}

@media (max-width: $extra-large-screen-width) {
  .fund-strategies {
    margin-left: 4%;
    margin-right: 4%;
  }
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  .fund-strategies {
    margin-top: 30px;
    .all-strategies {
      &.collapsed {
        .fund-strategy {
          &:nth-of-type(n + 5) {
            border-bottom: solid 2px $strategies-border-grey;
          }
          &:nth-of-type(n + 7) {
            border-bottom: none;
          }
        }
      }

      &.expanded {
        .fund-strategy {
          &:nth-of-type(n + 11) {
            border-bottom: solid 2px $strategies-border-grey;
          }
          &:nth-of-type(n + 13) {
            border-bottom: none;
          }
        }
      }

      .fund-strategy {
        width: 50%;
        &:nth-of-type(4n) {
          border-right: solid 2px $strategies-border-grey;
        }

        &:nth-of-type(2n) {
          border-right: none;
        }

        &:nth-of-type(n + 9) {
          border-bottom: solid 2px $strategies-border-grey;
        }

        &:nth-of-type(n + 11) {
          border-bottom: none;
        }
      }
    }
  }
}
