.modal {
  *:focus-visible {
    outline: -webkit-focus-ring-color auto 2px !important;
  }
  .modal-container {
    visibility: visible;
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    background-color: rgba(0, 0, 0, 0.4);

    display: flex;
    align-items: center;
    justify-content: center;

    > .modal-content {
      position: relative;
      box-sizing: border-box;
      border: 1px solid #000000;

      height: 100%;
      max-height: 500px;

      width: 525px;

      background-color: white;
      display: flex;
      flex-flow: column nowrap;

      .modal-header {
        h3 {
          margin: 0px;
          padding: 10px;
          font-size: 18px;
        }
        .close {
          cursor: pointer;
          position: absolute;
          right: 12px;
          top: 12px;
          z-index: 1;

          button {
            width: 16px;
            height: 16px;
            background-color: white;
            background-repeat: no-repeat;
            background-size: cover;
            border: none;
          }
        }
      }

      .modal-body {
        padding: 10px 20px;

        overflow: auto;
      }
    }
  }
}
@media (max-width: $medium-screen-width) {
  .modal {
    .modal-container {
      width: auto;
    }
    .modal-container > .modal-content {
      width: 100vw;
    }
  }
}
