%filter-styling {
  display: table;
  height: $criteria-menu-item-height;
  width: 100%;
  background-color: $criteria-menu-grey-lighter;
  border-bottom: $criteria-menu-border;
  cursor: pointer;
}

%criterion-item {
  display: table-cell;
  vertical-align: middle;
}

%filter-label {
  font-weight: bold;
  width: 55%;
}

%match-details {
  text-align: right;
  width: 30%;
  margin-right: 50px;
  padding-right: 15px;
  color: $criteria-menu-label-lighter;
}

%match-details-cumulative {
  font-size: $large-font-size;
  &.active {
    color: $criteria-menu-label;
  }
}
