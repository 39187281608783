.view-tabs-container {
  width: 100%;
  display: table;
  table-layout: fixed;
  margin: 0;
  padding: 0;
  border-top: 1px solid $medium-border-gray;
  .view-tab {
    @extend %tab-inactive;
    cursor: pointer;
    border-left: none;
    border-top: none;
    display: table-cell;
    text-align: center;
    height: 50px;
    vertical-align: middle;

    text-overflow: ellipsis;
    overflow: hidden;

    &.active {
      @extend %tab-active;
    }
  }
}

.view-tab-panel {
	padding: 0;
	margin: 0;
}
