.active-criterion-header {
  min-height: $criteria-menu-item-height;
  height: 1px; //must set a height value (any value works) so firefox will respect min-height
  width: 100%;
  display: table;
  background-color: $criteria-menu-grey;
  border-top: $criteria-menu-border;

  &.fundtype {
    .active-criterion-label.expanded {
      border-top: none;
    }
  }

  .active-criterion-label,
  .active-criterion-sublabel,
  .active-criterion-match-count,
  .remove-active-criterion {
    @extend %criterion-item;
  }

  .active-criterion-selection-text {
    display: block;
    color: black;
  }

  .active-criterion-label {
    width: 87%;
    box-sizing: border-box;
    padding: 2% 0;
    padding-left: 10px;

    &.collapsed {
      padding: 13px 0px;
      padding-left: 10px;
    }

    span {
      cursor: pointer;
      color: $link-color;
      font-weight: bold;
    }
    
    .dollarSignIcon{
      color: red;
      margin-right:5px;
      font-size: 13px;
      font-weight: 400;
    }

    .active-criterion-label-text:hover {
      .label-text{
        text-decoration: underline;
      }
    }

    span.active-criterion-selection-text {
      display: block;
      color: $criteria-menu-label-lighter;
      font-weight: normal;
    }
    img.leaficon {
      height: 24px;
    }
  }

  .active-criterion-sublabel {
    box-sizing: border-box;
    padding: 2% 0;
    padding-left: 10px;
    font-weight: bold;
  }

  .active-criterion-help-button {
    padding-left: 5px;
    img {
      vertical-align: text-top;
    }
  }

  .active-criterion-match-count {
    font-size: 16px;
  }

  .remove-active-criterion {
    width: 13%;
    box-sizing: border-box;
    position: relative;

    img {
      @extend %img-center;
      cursor: pointer;
      height: 12px;
      width: 12px;
      margin-top: 16px;
    }
  }
}

