html {
  background-color: $dolphin-grey;
}

body {
  background-color: white;
  font: $regular-font-size 'Fidelity Sans', Helvetica, Arial, sans-serif;
}

a {
  font-weight: bold;
  cursor: pointer;
  color: $link-color;
}

a:hover {
  text-decoration: underline;
}

.off {
  display: none;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 9px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

footer.fidelity-footer {
  background-color: $dolphin-grey;
  height: 80px;
  padding: 3px 8px 0px 0px;
  margin: 0px;
  text-align: right;
  font-size: 85%;
  line-height: 152%;
  width: auto;

  .footerLogo {
    margin-left: 16px;
    margin-top: 4px;
    float: left;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline;

      a {
        font-weight: normal;
      }
    }
  }
}

header.fidelity-header {
  position: relative;
  z-index: 1003;
  background: $fidelity-primary;

  .pgnb {
    width: auto;

    .pnmm .pnl1 .pnhs {
      z-index: 0;
    }
  }
}

.no-scroll::-webkit-scrollbar {
  display: none; // Safari and Chrome
}
.no-scroll {
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none;
  // Firefox
}
