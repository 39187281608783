.alphabet-selector {
  padding: 2% 14% 2% 2%;
  border-bottom: $criteria-menu-border;

  button {
    border: solid 1px transparent;
    margin-top: 2px;
    margin-right: 1px;
    margin-left: 2px;
    font-weight: bold;
    padding: 3px;
    box-sizing: border-box;

    &:focus {
      outline: none;
    }

    &.selected {
      background-color: $criteria-menu-grey-darkest;
    }

    &.active {
      border: solid 1px blue;
    }

    &.inactive {
      color: $disabled-link;
      cursor: default;
      pointer-events: none;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
