%img-center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

%rainbow-bg {
  background: rgba(179, 67, 47, 0.5);
  background: $webkit-rainbow-gradient;
  background: $opera-rainbow-gradient;
  background: $moz-rainbow-gradient;
  background: $linear-rainbow-gradient;
}

%modal-container {
  visibility: visible;
  position: fixed;
  z-index: 9999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.4);
}

%modal-content {
  position: relative;
  box-sizing: border-box;
  border: 1px solid #000000;
}

%tab-inactive {
  border: 1.5px solid #cccccc;
  border: 1.5px solid #cccccc;
  // background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(top, #e5e5e5 0, #e5e5e5 5px);
  background-image: -o-linear-gradient(top, #e5e5e5 0, #e5e5e5 5px);
  background-image: linear-gradient(to bottom, #e5e5e5 0, #e5e5e5 5px);
}

%tab-active {
  background-image: -webkit-linear-gradient(top, #f0f0f0 0, #fdfdfd 5px);
  background-image: -o-linear-gradient(top, #f0f0f0 0, #fdfdfd 5px);
  background-image: linear-gradient(to bottom, #f0f0f0 0, #fdfdfd 5px);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr='#fff0f0f0',
      endColorstr='#fffdfdfd',
      GradientType=0
    );
  border-right: 1px solid #ccc;
  border-bottom: 1px solid white;
}
