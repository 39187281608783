.madlibs-mobile-link {
  text-align: center;
  margin: 22px 0;
  font-size: 16px;
  line-height: 20px;
  display: none;
}

.madlibs-results {
  margin-left: 3%;
  width: 80%;
  margin-right: 3%;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 16px;
  width: 864px;
  color: #000000;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 19px;
}

.madlibs-selection {
  color: #346e94;
  font-weight: bold;
}
.madlibs-results-title {
  font-size: 15px;
  letter-spacing: 0;
  line-height: 19px;
}

.madlibs-container {
  margin: 0 4%;
  margin-top: 40px;
  padding-top: 40px;
  border-top: solid 1px #dbdbdb;

  .row {
    flex-wrap: wrap;
    justify-content: baseline;
    line-height: 63px;
  }

  h2 {
    font-size: 33px;
    line-height: 41px;
    margin-bottom: 24px;
  }

  .additionalCriteria-container {
    a {
      margin-left: 8px;
      vertical-align: middle;
      color: black;
      font-size: 14px;
      line-height: 18px;
      font-weight: normal;
      width: 50%;
    }
  }

  p {
    font-size: 16px;
    line-height: 22px;
    width: 50%;
    margin: auto;
    margin-bottom: 40px;
  }

  .plus {
    font-size: 25px;
    font-weight: bolder;
  }
}

.criteria-box {
  font-size: 14px;
  line-height: 18px;
  top: 25px;
  right: 0;
  left: 0;
  opacity: 0;
  padding: 10px 20px;
  border-radius: 4px;
  border: solid 1px #dbdbdb;
  background: white;
  cursor: pointer;
  display: none;

  &.active {
    opacity: 1;
    display: block;
    transform: translateY(0);
    width: 230px;
  }
}

.madlib-criterion {
  white-space: nowrap;
  display: table;
  padding: 5px;
  cursor: pointer;

  * {
    display: table-cell;
    vertical-align: middle;
  }

  span {
    padding-left: 10px;
  }

  &.selected {
    color: #3f8700;
    box-sizing: border-box;
    height: 27px;
    width: 166px;
    border: 1.5px solid #3f8700;
    border-radius: 2px;
    background-color: #f7fff0;
    margin-top: 20px;
    font-size: 14px;
    line-height: 18px;

    span {
      padding: 0;
      width: 90%;
    }

    .non-selectedImg {
      display: none;
      padding: 0;
    }

    .selectedImg {
      text-align: right;
      padding: 0;
    }
  }
}

.madlib-dropdown-wrap {
  position: relative;
  display: inline-block;
  width: 50%;
  margin-bottom: 10px;
  opacity: 1;
  visibility: visible;
  transition: all 0.1s ease;

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  &:nth-of-type(1) {
    .madlib-label.before {
      min-width: 211px;
    }
  }

  &:nth-of-type(2) {
    .madlib-label.before {
      min-width: 67px;
      padding-left: 10px;
    }
    .madlib-label.after {
      display: inline-block;
      min-width: 67px;
      padding-left: 10px;
    }
  }

  &:nth-of-type(3) {
    .madlib-label-field {
      min-height: 62.4px;
      .madlib-label.before {
        display: none;
      }
      .madlib-select-wrap {
        margin-left: 0;
      }
    }
  }

  &:nth-of-type(4) {
    .madlib-label.before {
      min-width: 67px;
      padding-left: 10px;
    }
    .madlib-select-wrap {
      margin-right: 0;
    }
  }

  .madlib-label-field {
    font-size: 25px;
    font-weight: 300;
    display: flex;
    &.disabled {
      .madlib-select-field {
        color: #666;
        cursor: default;
      }

      .madlib-dropdown-arrow {
        cursor: default;
      }
    }
  }
}

.quick-criteria-footer {
  margin-top: 0;
  display: flex;
  justify-content: flex-end;
}

.madlib-select-wrap {
  display: inline-block;
  position: relative;
  border-bottom: solid 1px black;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  cursor: pointer;
  flex-grow: 1;
  min-height: 35px;
  margin-top: 21px;
}

.madlib-select-field {
  width: calc(100% - 20px);
  min-width: 100px;
}

.madlib-label {
  display: inline-block;

  &.after {
    display: none;
  }
}

.madlib-label.before {
  margin-right: 5px;
  margin-left: 5px;
}

.madlib-dropdown {
  position: absolute;
  top: 32px;
  right: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 0.1s ease;
  padding: 10px 20px;
  border-radius: 4px;
  border: solid 1px #dbdbdb;
  background: white;
  z-index: 10;
  cursor: pointer;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .madlib-select {
    font-size: 15px;
    line-height: 20px;
    margin-top: 10px;
    font-weight: normal;

    &.disabled {
      color: #666;
      cursor: default;
    }

    &:first-child {
      margin-top: 0;
    }
  }
}

.madlib-dropdown-arrow {
  background: url('../media/images/madlibs-arrows.jpg') no-repeat;
  display: block;
  width: 30px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 6px;
  cursor: pointer;
}

.disabled {
  .madlib-select-wrap {
    border-bottom: solid 1px grey;
  }
  .madlib-label {
    color: #666;
  }

  .madlib-dropdown-arrow {
    background-position: bottom center;
  }
}

@media (max-width: $medium-screen-width) {
  .madlibs-mobile-link {
    display: block;
  }
}

@media (max-width: 1150px), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  .quick-criteria-footer {
    margin-top: 0;
    display: flex;
    justify-content: center;
  }

  .madlibs-container {
    p {
      margin-bottom: 60px;
      width: 80%;
    }

    .row {
      display: block;
      flex-wrap: wrap;
      display: flex;
    }

    .madlib-label-field {
      font-size: 18px;
    }

    .madlib-dropdown-wrap {
      display: block;
      width: auto;
      margin-bottom: 20px;
      min-width: 100%;

      &:nth-of-type(1) {
        .madlib-label-field {
          flex-wrap: wrap;
          display: flex;
          .madlib-label.before {
            min-width: 211px;
            margin-right: 10px;
          }
          .madlib-select-wrap {
            min-width: 100%;
          }
        }
      }

      &:nth-of-type(2) {
        .madlib-label.before {
          min-width: auto;
          margin-right: 10px;
          padding-left: 0px;
        }
        .madlib-label.after {
          display: none;
        }
      }

      &:nth-of-type(3) {
        .madlib-label.before {
          display: inline-block;
          min-width: auto;
          margin-right: 10px;
          padding-left: 0px;
        }
      }

      &:nth-of-type(4) {
        .madlib-select-wrap {
          margin-right: 10px;
        }
        .madlib-label.before {
          display: inline-block;
          min-width: auto;
          margin-right: 10px;
          padding-left: 0px;
        }
      }
    }
  }

  .madlib-select-wrap {
    padding-top: 0;
    font-size: 18px;
    line-height: 30px;
  }
}
