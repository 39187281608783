.criteria-group-header {
  display: table;
  height: $criteria-menu-item-height;
  width: 100%;

  .expander,
  .criteria-group-label {
    display: table-cell;
    vertical-align: middle;
  }

  .expander {
    width: 13%;
    box-sizing: border-box;
    background-color: $criteria-menu-grey;
    border-bottom: $criteria-menu-border;
    border-right: $criteria-menu-border;
    position: relative;
    text-align: center;

    img {
      display: table-cell;
      vertical-align: middle;
      margin: auto;
      cursor: pointer;
    }
  }

  .criteria-group-label {
    width: 87%;
    box-sizing: border-box;

    background-color: $criteria-menu-grey;
    border-bottom: $criteria-menu-border;

    .criteria-group-label-text {
      cursor: pointer;
      color: $criteria-menu-label;
      margin-left: 10px;
      font-weight: bold;
    }
    .criteria-group-count {
      color: $criteria-menu-label-lighter;
      cursor: pointer;
    }
  }
}
