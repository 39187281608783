.modal {
  .modal-container {
    > .view-all-modal {
      width: 85vw;
      max-height: 95vh;
      background-color: $view-all-modal-bg;

      .modal-body {
        padding: 0px;
        position: relative;
        overflow: unset;
        height: 100%;
      }
    }
  }
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  .modal {
    .modal-container {
      > .view-all-modal {
        width: 100vw;
        max-height: 100vh;
        background-color: $view-all-modal-bg;
      }
    }
  }
}
