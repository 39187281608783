.annuity-options {
  display: block;
  margin: 0 auto;
  padding: 15px 0 0;
  max-width: 820px;
  min-height: 500px;
  font-family: 'Fidelity Sans', Helvetica, Arial, sans-serif;
  font-size: 14px;

  h3 {
    margin: 2em 0 0 0;
    padding-left: 5px;
    font-weight: 700;
  }

  > p {
    font-size: 14px;
  }

  table {
    width: 100%;

    tr {
      td:last-child {
        width: 30%;
        text-align: center;
      }
    }
  }

  th {
    border-bottom: solid 1px #ccc;
    border-right: solid 1px #ccc;
    padding: 5px;
    text-align: center;
  }

  td {
    padding: 7px 5px;
    border-bottom: solid 1px #ccc;
    border-right: solid 1px #ccc;
  }
}

.annuities-product-modal {
  height: auto !important;
  width: 80% !important;

  .modal-title {
    font-weight: bold;
  }

  .go-to-positions-link {
    float: right;
    margin: 10px 20px;
  }

  .pricing-model-selections-container {
    display: flex;
    flex-wrap: wrap;

    .pricing-model-selection {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      position: relative;
      border-radius: 3px;
      margin: 10px 10px;
      padding: 15px;
      width: calc(33% - 20px);
      -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      -moz-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3); /* Firefox 3.5 - 3.6 */
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3); /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
      color: unset;
      font-weight: unset;

      .header {
        font-size: 12px;
        color: $text-soft;
      }

      .subheader {
        font-weight: bold;
      }

      .indicator {
        margin-right: 10px;
      }

      .fund-count-number {
        color: $fidelity-primary;
        font-weight: bold;
        font-size: 28px;
        margin-right: 5px;
      }

      .pricing-model-wrap {
        position: relative;
        height: 100%;
        width: 100%;
        display: table;
      }

      .status-and-fund-count {
        display: table-row-group;
        height: 1px;
      }

      .status-and-fund-count-wrap {
        width: 100%;
      }

      .status-count {
        margin-right: 15px;
      }

      .fund-count {
        float: right;
        margin-top: -14px;
      }
    }

    a.pricing-model-selection, a.pricing-model-selection:focus, a.pricing-model-selection:hover{
      text-decoration: none;
    }
  }
}

@media (max-width: 1300px) {
  .annuities-product-modal {
    .pricing-model-selections-container {
      .pricing-model-selection {
        .status-count {
          display: block;
          margin: 0;
        }
        .fund-count {
          display: block;
          text-align: right;
          float: none;
          margin-right: auto;
          margin-top: 0;
        }
      }
    }
  }
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  .annuity-options {
    margin: 0 4%;
  }

  .annuities-product-modal {
    width: 100% !important;

    .go-to-positions-link {
      margin: 10px 10px;
    }

    .modal-body {
      padding: 5px !important;

      .pricing-model-selections-container {
        .pricing-model-selection {
          width: calc(100% - 20px);

          .status-count {
            display: inline-block;
          }

          .fund-count {
            float: right;
            margin-top: -14px;
            display: inline-block;
          }
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .annuities-product-modal {
    .modal-body {
      .pricing-model-selections-container {
        .pricing-model-selection {
          .status-count {
            display: block;
            margin: 0;
          }
          .fund-count {
            display: block;
            text-align: right;
            float: none;
            margin-right: auto;
            margin-top: 0;
          }
        }
      }
    }
  }
}
