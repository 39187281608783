.faux-scroll-container {
  border-top: 1px solid $medium-border-gray;
  position: relative;
  z-index: 999;
  top: auto;
  bottom: 0;
  display: block;
  &.affix {
    position: fixed;
  }
}
.faux-scroll-wrapper {
  position: relative;
  overflow: hidden;
}
.faux-scroll-wrapper-container {
  height: 25px;
  width: 815px;
  background-color: $white;

  overflow-x: scroll;
  overflow-y: hidden;
}
#fixed-faux-scroll {
  height: 33px;
  background-color: $criteria-menu-grey-darker;
  width: 329px;
  float: left;
}
#faux-scroll {
  height: 25px;
  background-color: $criteria-menu-grey-darker;
}
#faux-scroll-bottom {
  height: 8px;
  background-color: $criteria-menu-grey-darker;
}
.faux-scroll-wrapper {
  border-left: 1px solid $criteria-menu-grey-darker;
}
