.quick-criteria-risk-slider {
  $radio-button-offset: 15.5px;
  margin-top: 20px;
  a {
    font-weight: normal;
  }

  input[type='range'].slider-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    cursor: pointer;
    width: calc(100% - #{$radio-button-offset});
    margin-bottom: 5px;
    padding: 0px;
    padding-right: 1%;

    &::-webkit-slider-thumb {
      cursor: pointer;
      -webkit-appearance: none;
      appearance: none;
      width: calc((100% * 0.091) + 1px);
      right: 1px;
      height: 20px;
      position: relative;
      background-repeat: no-repeat;
      background-color: transparent;
      border: 2px solid black;
      display: grid;
    }

    &::-moz-range-thumb {
      height: 20px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-color: transparent;
      border: 2px solid black;
    }

    &::-ms-thumb {
      width: calc((100% * 0.091) + 1px);
      right: 1px;
      height: 20px;
      cursor: pointer;
      color: transparent;
      border-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent;
      border: 2px solid black;
    }

    &::-webkit-slider-runnable-track {
      height: 20px;
      background: rgba(179, 67, 47, 0.5);
      background: $webkit-rainbow-gradient;
    }
    &::-moz-range-track {
      height: 20px;
      background: $moz-rainbow-gradient;
    }
    &::-ms-track {
      height: 5px;
      border-width: 5px 0;
      color: transparent;
      border-color: transparent;
      background: $linear-rainbow-gradient;
    }
    &::-ms-fill-lower,
    &:focus::-ms-fill-lower,
    &::-ms-fill-upper,
    &:focus::-ms-fill-upper {
      height: 20px;
      background-color: transparent;
    }
  }

  .labels {
    margin-left: $radio-button-offset;

    .left {
      float: left;
      margin-left: 4px;
    }
    .right {
      float: right;
      text-align: right;
    }
    clear: both;
    padding-bottom: 35px;
    font-size: 13px;
  }

  .description {
    margin-left: $radio-button-offset;
    clear: both;
    text-align: left;
    line-height: 1.4em;
  }
}
.disabled {
  .quick-criteria-risk-slider {
    input[type='range'].slider-input {
      &::-webkit-slider-runnable-track {
        background: $webkit-rainbow-gradient;
        filter: grayscale(1);
      }
      &::-moz-range-track {
        background: $moz-rainbow-gradient;
        filter: grayscale(1);
      }
      &::-ms-track {
        background: $linear-rainbow-gradient;
        filter: grayscale(1);
      }
      &::-webkit-slider-thumb {
        border: 0;
      }
      &::-moz-range-thumb {
        border: 0;
      }
      &::-ms-thumb {
        border: 0;
      }
    }
  }
}
