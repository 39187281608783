.quick-criteria-class-popup-header {
  background: white;
  min-height: 35px;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px $popup-border-grey;

  cursor: pointer;

  &.active {
    background: $popup-grey;
    border-top: solid 1px $dolphin-grey;
    border-bottom: solid 1px $dolphin-grey;
  }

  &:last-of-type {
    border-bottom: none;
  }
}
