.banners-section {
  .banner-container {
    border: 2px solid #5480a5;
    border-radius: 3px;
    display: table;
		margin-bottom: 10px;
    width: 100%;

    &.collapse-leave {
      opacity: 1;
    }

    &.collapse-leave.collapse-leave-active {
      opacity: 0.01;
      transition: opacity 300ms ease-in;
    }

    .info-icon {
      width: 25px;
      padding: 20px 10px;
      background: #5480a5;
      display: table-cell;
      text-align: center;
      height: 100%;
      vertical-align: middle;
      min-height: 25px;
      img {
        min-height: 25px;
        min-width: 25px;
      }
    }
    .banner-main {
      display: table-cell;
      padding: 15px;
      padding-right: 50px;
      font-size: 16px;
      color: black
    }
    .close-icon {
      display: table-cell;
      width: 35px;
      vertical-align: middle;
      padding-right: 10px;
      position: relative;
      .close-x {
        position: absolute;
        width: 22px;
        height: 22px;
        top: 35%;
        cursor: pointer;

        &:before {
          content: '';
          position: absolute;
          top: 10px;
          width: 22px;
          height: 2px;
          background-color: grey /*@lt-grey*/;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }

        &:after {
          content: '';
          position: absolute;
          top: 10px;
          width: 22px;
          height: 2px;
          background-color: grey /*@lt-grey*/;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }
}