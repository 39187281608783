.search-bar {
  position: relative;
  width: 145px;

  input[type='text'] {
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    padding-right: 33px;
    border-radius: 4px;
    border: solid 1px #bbb;
  }

  ul {
    position: absolute;
    top: 100%;
    left: 0px;
    right: auto;
    margin: 0;
    padding: 0;
    max-height: 300px;
    overflow: auto;

    display: block;
    background: white;
    width: 80%;
    z-index: 99999;

    border: 1px solid #999;
    box-sizing: border-box;

    li {
      display: block;
      cursor: pointer;

      &[aria-selected='true'] {
        background: #ccc;
      }

      .suggestion {
        padding: 5px;

        * {
          display: inline;
          vertical-align: initial;
        }

        &.disabled {
          color: grey;
          cursor: not-allowed;
        }
      }
    }
  }
}