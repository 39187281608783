.results-sub-title {
  padding: 10px 0px;
  padding-bottom: 0px;
  box-sizing: border-box;
  font-size: 16px;

  .top-level-section {
    padding-bottom: 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .sort-selector {
      padding-left: 15px;

      .current-sort-field {
        display: inline-block;
        outline: none;
      }
    }

    .view-selector {
      padding-right: 15px;

      div {
        display: inline-block;
      }

      button {
        outline: none;

        &.as-link.active {
          cursor: initial;
          font-weight: bold;
          color: black;
        }
      }
    }
  }

  .all-sort-fields {
    .sort-field-row {
      border-top: solid 1px $results-card-border;
      padding: 5px 15px;

      button {
        outline: none;

        &.selected:hover {
          text-decoration: none;
        }

        &.selected {
          color: $black;
          font-weight: bold;
        }
      }
    }
  }
}
