.criteria-menu-header {
  display: table;
  box-sizing: border-box;
  table-layout: fixed;
  height: 50px;
  background-color: $dolphin-grey;
  border-top: $criteria-menu-border;
  cursor: pointer;

  &.expanded {
    width: 100%;

    .criteria-wrap .clear-all {
      display: none;
    }
  }

  &.collapsed {
    .criteria-menu-title,
    .hide-button,
    .clear-all {
      display: none;
    }
  }

  .criteria-funnel-icon,
  .criteria-menu-title,
  .hide-button {
    display: table-cell;
    vertical-align: middle;

    &.off {
      display: none;
    }
  }

  .criteria-funnel-icon {
    width: 35px;
	button {
		border:none;
		padding:0;
		img {
		margin-left: 10px;
		height: auto;
		width: auto;
		}
	}
  }

  .criteria-wrap {
    display: table-cell;
    vertical-align: middle;
    width: auto;
  }

  .clear-all {
    display: table-cell;
    vertical-align: bottom;
    padding-bottom: 1px;
    width: 75px;

    &.off {
      display: none;
    }
  }

  .criteria-wrap {
    width: 230px;
  }

  .criteria-menu-title {
    font-size: 18px;
  }

  .show-button {
    display: none;
  }

  .toggle-button {
    text-align: right;
  }
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  .criteria-menu-header {
    width: 100%;

    .criteria-wrap {
      width: auto;
    }

    .clear-all {
      display: table-cell;
    }

    .show-button,
    .hide-button {
      width: auto;
    }

    &.collapsed {
      .clear-all {
        display: table-cell;
      }

      .criteria-menu-title {
        display: table-cell;
      }

      .show-button {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}
