.star {
  position: relative;

  display: inline-block;
  width: 0;
  height: 0;

  margin-left: 0.9em;
  margin-right: 0.9em;
  margin-bottom: 1.5em;

  border-right: 0.3em solid transparent;
  border-bottom: 0.7em solid $star-icon-yellow;
  border-left: 0.3em solid transparent;

  font-size: 24px;

  &:before,
  &:after {
    content: '';

    display: block;
    width: 0;
    height: 0;

    position: absolute;
    top: 0.6em;
    left: -1em;

    border-right: 1em solid transparent;
    border-bottom: 0.7em solid $star-icon-yellow;
    border-left: 1em solid transparent;

    transform: rotate(-35deg);
  }

  &:after {
    transform: rotate(35deg);
  }

  &.greyed-out {
    border-bottom: 0.7em solid $icon-greyed-out;

    &:before,
    &:after {
      border-bottom: 0.7em solid $icon-greyed-out;
    }
  }
}

.dropdown-arrows {
  &:before {
    content: '';
    position: absolute;
    right: 7px;
    top: 4px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;

    border-bottom: 5px solid black;
  }

  &:after {
    content: '';
    position: absolute;
    right: 7px;
    bottom: 4px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;

    border-top: 5px solid black;
  }
}

.dropdown-arrows-small {
  &:before {
    content: '';
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -5px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 4px solid black;
  }

  &:after {
    content: '';
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: 2px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid black;
  }

  &.text-as-link {
    &:before {
      border-bottom: 4px solid $link-blue;
    }

    &:after {
      border-top: 4px solid $link-blue;
    }
  }
}

.risk-icon-gradient {
  @extend %rainbow-bg;
  position: relative;
  display: table-cell;
  height: 10px;
  width: 90px;

  &:after {
    position: absolute;
    right: 36px;
    top: -6px;
    content: ' ';
    display: block;
    width: 9px;
    height: 9px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    background: $criteria-menu-grey;
  }

  &.risk-level-10:after {
    right: 3%;
  }

  &.risk-level-9:after {
    right: 13%;
  }

  &.risk-level-8:after {
    right: 23%;
  }

  &.risk-level-7:after {
    right: 33%;
  }

  &.risk-level-6:after {
    right: 43%;
  }

  &.risk-level-5:after {
    right: 53%;
  }

  &.risk-level-4:after {
    right: 63%;
  }

  &.risk-level-3:after {
    right: 73%;
  }

  &.risk-level-2:after {
    right: 83%;
  }

  &.risk-level-1:after {
    right: 88%;
  }
}

%arrow-icon {
  content: ' ';
  border: solid transparent;
  border-width: 8px 5px;
  margin-left: 5px;
  position: absolute;
}

.sort-arrow {
  &:after {
    @extend %arrow-icon;
  }

  &.asc {
    &.active:after {
      border-bottom-color: $link-color;
    }

    &.inactive:after {
      border-bottom-color: black;
    }
  }

  &.desc {
    &.active:after {
      margin-top: 8px;
      border-top-color: $link-color;
    }

    &.inactive:after {
      margin-top: 8px;
      border-top-color: black;
    }
  }
}

.new-icon {
  position: relative;

  &:after {
    content: 'NEW';
    background-color: white;
    font-size: 60%;
    font-size: calc(100% / 2 + 1px);
    font-weight: bold;
    padding: 5px 6px;
    border: 1px solid $cta-orange;
    border-radius: 3px;
    color: $cta-orange;
    margin-left: 6px;
    display: inline-block;
    line-height: initial;
    vertical-align: middle;
    position: relative;
    z-index: 5;
  }

  &.inline-new-icon {
    &:after {
      padding: 3px 5px;
      margin: 0px 0px 1px 4px;
      margin-left: 4px;
    }
  }
}

.box-scale {
  white-space: nowrap;

  .box-scale-blue {
    @include box-scale-item($morningstar-icon-blue, 12px);
  }

  .box-scale-gray {
    @include box-scale-item($icon-greyed-out, 12px);
  }
}

.alert-circle {
  margin-left: 2px;
  margin-top: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 1px $negative;
  position: absolute;

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    background-color: inherit;
  }

  &:before {
    top: 3px;
    left: 7px;
    width: 2px;
    height: 7px;
  }

  &:after {
    top: 11px;
    left: 7px;
    width: 2px;
    height: 2px;
  }

  &.filled {
    background-color: $negative;

    &:before,
    &:after {
      background-color: $white;
    }
  }
}

.transparent-btn {
	border: none;
	background-color: transparent;
}