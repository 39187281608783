.view-all {
  .view-all-open-button {
    text-align: center;

    background-color: $criteria-menu-grey;
    span {
      color: $link-color;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
