.criteria-selection-menu-header {
  background-color: $criteria-menu-grey;
  border-bottom: $criteria-menu-border;

  .title {
    background-color: $dolphin-grey;
    border-top: $criteria-menu-border;
    border-bottom: $criteria-menu-border;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;
    font-size: 0.9em;
    color: $breadcrumbs-grey;
  }

  .selection-content {
    padding: 22px 10px;

    .search-bar,
    .separator-text,
    .view-all {
      display: inline-block;
    }

    .search-label {
      display: inline-block;
      padding-right: 5px;
      font: 14px "Fidelity Sans", Helvetica, Arial, sans-serif;
      margin: 0;
      color: #333;
    }

    .separator-text {
      margin-left: 15px;
    }

    .view-all {
      margin-left: 15px;
    }
  }
}