.loading {
  position: relative;
  &:before {
    content: ' ';
    background-color: #fff;
    opacity: 0.7;
    filter: alpha(opacity=70);
    z-index: 999;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  &:after {
    content: ' ';
    background-image: url(../media/images/loader.gif);
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 1000;
    height: 100%;
    width: 100%;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    max-height: 80vh;
  }
  &.small-loading {
    &:after {
      background-size: 30px;
    }
  }
}
