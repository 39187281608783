#fund-selections-detail {
  .fund-selection {
    display: table-row;
    div {
      display: table-cell;
      vertical-align: middle;
    }
    .remove-fund-container {
      padding-right: 5px;
      .close-icon.close-icon-small {
        height: 10px;
        width: 10px;
        cursor: pointer;
        display: block;
      }
    }
  }
}
