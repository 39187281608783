.modal {
  .modal-container {
    > .quick-criteria-results-modal {
      height: auto;
      padding: 15px;
      .modal-body {
        .header {
          font-size: 22px;
          font-weight: bold;
        }

        .main {
          p:first-of-type {
            margin-bottom: 30px;
          }

          .include-selections,
          .exclude-selections {
            margin-top: 7px;
            label {
              cursor: pointer;
            }
            input[type='radio'] {
              margin-right: 8px;
              pointer-events: none;
            }
          }
        }

        .footer {
          margin-top: 45px;

          .hide-alert {
            input,
            label {
              cursor: pointer;
              float: left;
            }

            label {
              width: 90%;

              input[type='checkbox'] {
                margin-right: 8px;
                pointer-events: none;
              }
            }
          }

          .cancel {
            text-align: center;
            margin-top: 5px;
          }

          .view-results {
            text-align: center;

            button {
              @include action-button(
                $quick-criteria-menu-light-green,
                $quick-criteria-menu-green,
                $quick-criteria-menu-dark-green
              );
              width: 100%;
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  .modal {
    .modal-container > .quick-criteria-results-modal {
      width: 95vw;
      .footer {
        .row {
          div:not(:first-of-type) {
            margin-top: 10px;
          }
        }
        .view-results {
          button {
            margin-left: 0px;
          }
        }
      }
    }
  }
}
