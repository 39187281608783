.active-criteria-menu {
  border-top: $criteria-menu-border;
  border-right: inherit;

  div:last-of-type {
    .active-criterion-header {
      border-bottom: none;
    }
  }

  .no-criteria {
    border-top: $criteria-menu-border;
    background-color: $criteria-menu-grey;
    padding: 15px 0;
    text-align: center;

    div:first-of-type {
      font-weight: bold;
    }
  }

  .title {
    background-color: $dolphin-grey;
    padding: 10px;
    display: flex;
    line-height: 16px;
    span {
      text-transform: uppercase;
      font-weight: bold;
      color: $breadcrumbs-grey;
      flex: auto;
      font-size: 0.9em;
    }
    .clear-all button {
      padding: 0 3px;
    }
  }

  .matches-container {
    background-color: $criteria-menu-grey;
    border-top: $criteria-menu-border;
    text-align: right;
    padding-right: 35px;
    height: 45px;
    span {
      line-height: 45px;
    }
    .matches-label {
      font-size: 11px;
    }
    .matches-value {
      font-size: 16px;
      padding-left: 15px;
      padding-top: 2px;
    }
  }
}
