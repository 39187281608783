.pricing-model-popup-container {
  margin-bottom: 4px;
  white-space: nowrap;
  max-width: 520px;
}

.pricing-model-popup-label {
  font-size: 14px;
  vertical-align: text-top;
  display: inline-block;
  line-height: 24px;
  vertical-align: text-top;
  margin-right: 5px;
}

.pricing-model-popup {
  vertical-align: text-top;
  display: inline-block;
  min-width: 200px;
  max-width: calc(100% - 56px);

  .selection-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: table;
  }

  .indicator {
    vertical-align: middle;
    padding: 2px 10px;
    position: relative;
    top: -1px;
    margin-left: 8px;
  }

  .options {
    height: 26px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    cursor: pointer;
    position: relative;
    border: solid 1px $quick-criteria-menu-border-grey;
    padding: 3px 22px 3px 7px;
    background: $white;
    border-radius: 5px;
    line-height: 16px;
    display: table-cell;
    vertical-align: bottom;
  }

  .dropdown-arrows:before {
    display: none;
  }

  .dropdown-arrows:after {
    bottom: 10px;
  }

  .pricing-model-popup-options {
    position: absolute;
    top: 38px;
    background: $white;
    border: solid 1px $popup-border-grey-darker;
    min-width: 45vw;
    z-index: 9;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08), 0 1px 6px rgba(0, 0, 0, 0.12);
    text-overflow: ellipsis;
    overflow: hidden;

    .pricing-model-popup-option-header {
      background: white;
      min-height: 35px;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: solid 1px #e2e2e2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;

      &.active {
        background: $popup-grey;
        border-top: solid 1px $dolphin-grey;
        border-bottom: solid 1px $dolphin-grey;
      }

      &:last-of-type {
        border-bottom: none;
      }

      .indicator {
        display: inline-block;
      }
    }
  }
}

.indicator {
  color: $white;
  border-radius: 3px;
  padding: 2px 10px;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 1px;

  &.closed {
    background: $link-color;
  }

  &.open {
    background: $fidelity-primary;
  }
}

.close-indicator-description {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 15px;

  .indicator {
    margin-right: 5px;
  }
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  .pricing-model-popup-container {
    padding: 0 15px;
  }

  .pricing-model-popup {
    .pricing-model-popup-options {
      top: 113px;
    }
  }
}

@media (max-width: 767px) {
  .close-indicator-description {
    padding: 0px 15px;
  }

  .pricing-model-popup-container {
    padding: 0px 15px;
  }

  .pricing-model-popup {
    .options {
      display: block;
    }

    .selection-label {
      display: block;
    }

    .pricing-model-popup-options {
      top: 50px;
      left: 0px;
      max-width: 100%;
    }
  }
}
