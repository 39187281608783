.criteria-menu {
  display: inline-block;
  vertical-align: top;
  border-right: $criteria-menu-border;
  border-left: $criteria-menu-border;
  border-bottom: $criteria-menu-border;
  width: $criteria-menu-width;
  flex: none;

  &.collapsed {
    width: $criteria-menu-closed-width;
    position: relative;

    .closed-criteria-menu-content {
      img {
        position: absolute;
        cursor: pointer;
        top: 60px;
        left: 13px;
      }
    }
  }
}

@media (max-width: $large-screen-width) {
  div.criteria-menu {
    position: absolute;
    z-index: inherit;
    box-shadow: 10px 0 15px -7px #999;
    min-height: auto;

    &.expanded ~ .landing-page-container {
      width: 100%;
    }

    &.collapsed {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &.collapsed ~ .landing-page-container {
      width: calc(100% - #{$criteria-menu-closed-width});
      position: relative;
    }
  }
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  div.criteria-menu {
    position: relative;
    box-shadow: none;
    border-bottom: none;
    width: 100%;
    z-index: inherit;
    height: 100%;

    &.expanded {
      position: sticky;
      top: 0;
      margin: 0 auto;

      &:after {
        content: ' ';
        z-index: -1;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
      }
      .open-criteria-menu-content {
        width: calc(-2px + #{$criteria-menu-width}); /* subtracting 2px to account for the border width*/
        padding-bottom: env(safe-area-inset-bottom);
        max-height: calc(-200px + 100vh);
        overflow-y: auto;
        -webkit-overflow-scrolling: auto;
      }
    }

    &.collapsed {
      position: sticky;
      top: 0;
      width: 100%;
      border-bottom: $criteria-menu-border;
      min-height: auto;
      max-height: 50px;
    }
    .closed-criteria-menu-content {
      display: none;
    }
  }
}
