$fidelity-primary: #568200;
$fidelity-light-green: #f2f7e8;
$fidelity-green: #6f9824;

$fidelity-secondary-lighter: #fefefe;
$fidelity-secondary-darker: #e8e8e8;
$fidelity-secondary-border: #cccccc;

$light-border-color: #dbdbdb;
$darker-border-color: #cccccc;
$light-background-grey: #fbfbfb;
$medium-border-gray: #ccc;
$results-border-gray: #999;

$results-table-dotted-border: 1px dotted $results-border-gray;

$default-color: #333;
$black: #000;
$dark-grey: #666;
$white: white;

$mobile-header-background-grey: #f2f2f2;

$criteria-menu-label: #000;
$criteria-menu-label-lighter: #5d605f;

$results-card-light-grey: $light-background-grey;
$results-card-grey: #ededed;
$results-card-border: $light-border-color;

$results-title-grey: #f6f6f6;

$link-color: #0f57c2;
$disabled-link: #bdbabe;
$disabled-radio: #989898;

$criteria-menu-grey: #f9f9f9;
$criteria-menu-grey-darker: #f5f5f5;
$criteria-menu-grey-darkest: $light-border-color;
$criteria-menu-grey-lighter: #ffffff;
$criteria-menu-nested-item: #ededed;

$popup-light-grey: #f9f9f9;
$popup-grey: #f0f0f0;
$popup-border-grey: #e2e2e2;
$popup-border-grey-darker: #d8d8d8;

$dolphin-grey: #eeeeee;
$breadcrumbs-grey: $dark-grey;

$criteria-menu-border: solid 1px $light-border-color;

$view-all-modal-bg: #efefef;

$morningstar-icon-blue: #1b4472;
$icon-greyed-out: #cdcdcd;
$star-icon-yellow: #eca400;

$quick-criteria-menu-light-green: #7aac4e;
$quick-criteria-menu-green: #3f8700;
$quick-criteria-menu-dark-green: #55842d;
$quick-criteria-menu-border-grey: #d6d6d6;
$asset-class-dropdown-border: #cccccc;

$strategies-border-grey: #e2e2e2;

$action-button-light-blue: #9bc7e6;
$action-button-blue: #0069b6;
$action-button-dark-blue: #004988;

$link-blue: #0f57c2;

$fidelity-result-green: #f2f7e8;
$sorted-fidelity-result-green: #e6f0d7;
$sorted-result: #f3f3f3;

$positive: #007900;
$negative: #cc0000;

$cta-orange: #fb9914;

$text-soft: #7b7b7b;

$error: #a94442;

$banner-blue: #5480a5;

$webkit-rainbow-gradient: -webkit-linear-gradient(left, #7a962d, #e0b43c, #e09b40, #b3432f);
$opera-rainbow-gradient: -o-linear-gradient(right, #7a962d, #e0b43c, #e09b40, #b3432f);
$moz-rainbow-gradient: -moz-linear-gradient(left, #7a962d, #e0b43c, #e09b40, #b3432f);
$linear-rainbow-gradient: linear-gradient(to right, #7a962d, #e0b43c, #e09b40, #b3432f);

$webkit-rainbow-gradient: -webkit-linear-gradient(left,
    #7a962d 0% 9.1%,
    #ffffff 9.1% 10.1%,
    #9b9f32 10.1% 19.2%,
    #ffffff 19.2% 20.2%,
    #baa937 20.2% 29.3%,
    #ffffff 29.3% 30.3%,
    #d8b13a 30.3% 39.4%,
    #ffffff 39.4% 40.4%,
    #e0b13d 40.4% 49.5%,
    #ffffff 49.5% 50.5%,
    #e0a53f 50.5% 59.6%,
    #ffffff 59.6% 60.6%,
    #df9a40 60.6% 69.7%,
    #ffffff 69.7% 70.7%,
    #d4823c 70.7% 79.8%,
    #ffffff 79.8% 80.8%,
    #c86a37 80.8% 89.9%,
    #ffffff 89.9% 90.9%,
    #b74a31 90.9% 100%);

$opera-rainbow-gradient: -o-linear-gradient(right,
    #7a962d 0% 9.1%,
    #ffffff 9.1% 10.1%,
    #9b9f32 10.1% 19.2%,
    #ffffff 19.2% 20.2%,
    #baa937 20.2% 29.3%,
    #ffffff 29.3% 30.3%,
    #d8b13a 30.3% 39.4%,
    #ffffff 39.4% 40.4%,
    #e0b13d 40.4% 49.5%,
    #ffffff 49.5% 50.5%,
    #e0a53f 50.5% 59.6%,
    #ffffff 59.6% 60.6%,
    #df9a40 60.6% 69.7%,
    #ffffff 69.7% 70.7%,
    #d4823c 70.7% 79.8%,
    #ffffff 79.8% 80.8%,
    #c86a37 80.8% 89.9%,
    #ffffff 89.9% 90.9%,
    #b74a31 90.9% 100%);

$moz-rainbow-gradient: -moz-linear-gradient(left,
    #7a962d 0% 9.1%,
    #ffffff 9.1% 10.1%,
    #9b9f32 10.1% 19.2%,
    #ffffff 19.2% 20.2%,
    #baa937 20.2% 29.3%,
    #ffffff 29.3% 30.3%,
    #d8b13a 30.3% 39.4%,
    #ffffff 39.4% 40.4%,
    #e0b13d 40.4% 49.5%,
    #ffffff 49.5% 50.5%,
    #e0a53f 50.5% 59.6%,
    #ffffff 59.6% 60.6%,
    #df9a40 60.6% 69.7%,
    #ffffff 69.7% 70.7%,
    #d4823c 70.7% 79.8%,
    #ffffff 79.8% 80.8%,
    #c86a37 80.8% 89.9%,
    #ffffff 89.9% 90.9%,
    #b74a31 90.9% 100%);

$linear-rainbow-gradient: linear-gradient(to right,
    #7a962d 0% 9.1%,
    #ffffff 9.1% 10.1%,
    #9b9f32 10.1% 19.2%,
    #ffffff 19.2% 20.2%,
    #baa937 20.2% 29.3%,
    #ffffff 29.3% 30.3%,
    #d8b13a 30.3% 39.4%,
    #ffffff 39.4% 40.4%,
    #e0b13d 40.4% 49.5%,
    #ffffff 49.5% 50.5%,
    #e0a53f 50.5% 59.6%,
    #ffffff 59.6% 60.6%,
    #df9a40 60.6% 69.7%,
    #ffffff 69.7% 70.7%,
    #d4823c 70.7% 79.8%,
    #ffffff 79.8% 80.8%,
    #c86a37 80.8% 89.9%,
    #ffffff 89.9% 90.9%,
    #b74a31 90.9% 100%);