table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}
th,
td {
  vertical-align: middle;
}
caption,
th {
  text-align: left;
}
fieldset,
img,
abbr,
acronym {
  border: 0;
}
address,
caption,
cite,
code,
dfn,
em,
th,
var {
  font-style: normal;
  font-weight: normal;
}
strong {
  font-weight: bold;
}
ol,
ul {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  color: $default-color;
}
a {
  color: $link-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
