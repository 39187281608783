.start-page {
  margin-left: 3%;
  margin-top: 20px;
  margin-right: 3%;
  padding-bottom: 30px;
  border-bottom: solid 1px $light-border-color;

  .search-bar-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    &.off {
      display: none;
    }

    margin-bottom: 40px;
  }

  .view-tabs {
    display: flex;
    height: 54px;
    border-top: solid 2px $medium-border-gray;
    text-align: center;
    margin-bottom: 20px;

    .active {
      @extend %tab-active;
    }

    .inactive {
      @extend %tab-inactive;
      cursor: pointer;
      border-top: none;
    }

    > div {
      border-left: solid 1px $medium-border-gray;
      display: flex;
      justify-content: center;
    }

    button {
      font-size: 16px;
    }

    .active button {
      color: black;
      font-weight: bold;
      outline: none;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  .start-page {
    margin: 0px;

    div.view-tabs {
      height: 70px;
      position: relative;
      border-top: none;
      border-bottom: solid 2px $medium-border-gray;
      margin-bottom: 0px;

      .active,
      .inactive {
        border-bottom: none;
        border-right: none;
        background-image: none;
      }

      .active {
        &:after {
          @include chevron($medium-border-gray, white, 15px);
          right: 48%;
          bottom: -8px;
        }
      }
    }
  }
}

@media (max-width: 440px) {
  .start-page {
    div.view-tabs {
      .active,
      .inactive {
        button {
          font-size: 12px;
        }
      }
    }
  }
}
