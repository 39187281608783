.quick-criteria-morningstar-ratings {
  margin-left: 10px;
  margin-right: 10px;

  .large-header {
    @extend %quick-criteria-large-header;
    margin-bottom: 10px;
  }

  .small-header {
    @extend %quick-criteria-small-header;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: bold;
  }

  .morningstar-disclaimer {
    padding: 10px 0 10px 0;
    font-size: 13px;
    color: $dark-grey;
  }

  .row {
    padding: 10px 0 10px 0;
    .col:first-of-type {
      width: 100px;
      float: left;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  div.icons {
    white-space: nowrap;
  }

  .box {
    display: inline-block;
    color: $morningstar-icon-blue;
    background: $morningstar-icon-blue;
    margin-left: 3px;
    margin-bottom: 1px;
    width: 13px;
    height: 14px;

    &.greyed-out {
      background: $icon-greyed-out;
      color: $icon-greyed-out;
    }
  }
}

@media (max-width: $large-screen-width) {
  .quick-criteria-morningstar-ratings {
    margin: 0;
    padding: 0;
    div.large-header {
      margin-top: 25px;
    }
  }
}
