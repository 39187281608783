.results-container {
  border-right: 1px solid $light-border-color;
  position: relative;
  .table-container {
    padding: 10px 0 0 20px;

    .fidelity-results-table-title {
      padding: 5px 0;

      .left-title-items {
        display: inline-block;

        span {
          text-align: left;
          font-size: 14px;
        }
      }

      .right-title-items {
        float: right;
      }

      .help-me-read-this-table {
        .popup-icon {
          vertical-align: middle;
        }
      }
    }
    .table-wrapper {
      display: flex;
      min-height: 130px;
      table {
        table-layout: fixed;

        tbody {
          font-size: 12px;
          color: #333;
          tr {
            min-height: 36px;
            border-bottom: $results-table-dotted-border;
            &:last-of-type {
              border-bottom: 1px solid $light-border-color;
            }

            &.results-table-title {
              height: 32px;

              border-bottom: 1px solid $light-border-color;
              td {
                padding-left: 5px;
                font-size: 14px;
                vertical-align: bottom;
                font-weight: bold;
              }
            }
            &.fidelity-light-green {
              background-color: $fidelity-result-green;
              td.sorted-column-cell {
                background-color: $sorted-fidelity-result-green;
              }
            }

            td {
              padding: 5px 10px 5px 5px;
              max-width: 300px;
			  background-color: white;
              &.sorted-column-cell {
                background-color: $sorted-result;
              }
              &.fund-name {
                @media (max-width: 1500px) {
                  max-width: 175px;
                }
                @media (max-width: 1300px) {
                  max-width: 150px;
                }
                @media (max-width: 1200px) {
                  max-width: 100px;
                }
              }
              .star-group {
                white-space: nowrap;
                text-align: center;
                padding: 0 5px;
                .star {
                  font-size: 5px;
                  line-height: 10px;
                }
              }
              .soft {
                white-space: nowrap;
              }

              @include risk-icon-container();
              .results-as-of-date,
              .number-of-funds {
                display: inline-block;
                width: 100%;
              }
              .results-transaction-fee {
                display: inline-block;
                margin-right: 4px;
                color: red;
              }
            }

            @include risk-icon-container();
            .results-as-of-date,
            .number-of-funds {
              display: inline-block;
              width: 100%;
            }
            .results-transaction-fee {
              display: inline-block;
              margin-right: 4px;
              color: red;
            }
            .status-description-asterisks {
              display: inline-block;
              margin-right: 4px;
            }
            .buy-button {
              @include action-button(
                $quick-criteria-menu-light-green,
                $quick-criteria-menu-green,
                $quick-criteria-menu-dark-green
              );
			  padding: 6px 10px 0 10px;
            }
            & > span {
              display: block;
            }
            &.no-results {
              height: 45px;
              font-weight: bold;
            }
          }
        }
      }
    }
	.name {
		border-right: 1px solid #dbdbdb;		
	}
  }
}
.scrollable-results-table-wrapper {
  overflow-x: scroll;
  flex-grow: 2; /* hiding scroll bar */
  #scrollable-results-table {
    width: 100%;
    table-layout: auto;
  }
}
.box-scale-container {
  text-align: center;
  font-size: 11px;
}
.table-disclosure-content {
  padding: 0 20px;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 15px;
  color: #666;
}
.screen-reader {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}