#results-header,
#fixed-results-header {
  background-color: white;
  z-index: 5;
  border-top: 1px solid $light-border-color;

  &.fixed,
  &.fixed-mobile {
    position: fixed;
    overflow: hidden;
    min-width: 250px;
  }
  &.fixed {
    top: 0;
  }
  &.fixed-mobile {
    top: 50px;
  }
  &.hidden {
    display: none;
    top: -1000px;
  }
  tr {
    &:only-of-type {
      height: inherit;
    }
    &:last-of-type {
      vertical-align: bottom;
    }
    border-bottom: 1px solid $light-border-color;
    &.grouped-column-headers {
      border: none;
      .column-group {
        padding: 0 2px;
        .column-group-label {
          text-align: center;
          padding: 5px;
          white-space: nowrap;
          display: block;
          border-bottom: 1px solid $light-border-color;
          margin-top: 5px;
          position: relative;
          &:after,
          &:before {
            content: ' ';
            height: 100%;
            bottom: -50%;
            position: absolute;
            z-index: 100;
          }
          &:after {
            border-right: 1px solid $light-border-color;
            right: 0;
          }
          &:before {
            border-left: 1px solid $light-border-color;
            left: 0;
          }
        }
        &.sort-desc,
        &.sort-asc {
          .column-group-label {
            border-bottom: 1px solid $darker-border-color;
            &:after {
              border-right: 1px solid $darker-border-color;
              right: 0;
            }
            &:before {
              border-left: 1px solid $darker-border-color;
              left: 0;
            }
          }
        }
      }
    }
    th {
      font-weight: bold;
      vertical-align: bottom;
      &.sort {
        cursor: pointer;
      }
      > .header-label-container {
        border: none;
        background-color: transparent;
        .header-label {
          display: flex;
        }
        padding-right: 10px;
        padding-left: 5px;
        padding-bottom: 2px;
		margin-bottom: 2px;
        .no-wrap {
          white-space: nowrap;
        }
        .disclosure-indicator {
          font-weight: normal;
        }
      }
    }

    th.sort-asc,
    th.sort-desc,
    th.sorted-column-cell {
      background-color: $sorted-result;
    }

    th.sort-asc,
    th.sort-desc {
      .header-label-container {
        border-bottom: 2px solid #000;
        padding-bottom: 0px;
      }
      &.column-group {
        border-bottom: none;
        .column-group-label {
          padding: 5px 15px 5px 10px;
        }
      }
    }
    th.sort-desc.sort,
    th.sort-desc.column-group {
      background-image: url('../media/images/caret_down.png');
      background-position: bottom 3px right 2px;
      background-repeat: no-repeat;
      background-size: 8px 8px;
    }
    th.sort-asc.sort,
    th.sort-asc.column-group {
      background-image: url('../media/images/caret_up.png');
      background-position: bottom 3px right 2px;
      background-repeat: no-repeat;
      background-size: 8px 8px;
    }
    th.sort-desc.column-group,
    th.sort-asc.column-group {
      &:after {
        right: 10px;
        bottom: 50%;
        margin-bottom: -5px;
      }
    }
    th.sort-asc.sort:after,
    th.sort-asc.column-group:after {
      border: 3px solid black;

      border-bottom: 3px solid transparent;
      border-left: 3px solid transparent;
    }
  }
}

#fixed-results-header {
  -webkit-box-shadow: -2px 8px 6px -6px rgba(153, 153, 153, 0.7), -2px 8px 6px -6px rgba(153, 153, 153, 0.7);
  box-shadow: -2px 8px 6px -6px rgba(153, 153, 153, 0.7), -2px 8px 6px -6px rgba(153, 153, 153, 0.7);
  border-collapse: separate;
  background-color: #fff;
}
.col-static {
  position: sticky;
  z-index: 2;
  left:0;
  background-color: white;
}
.col-Action {
  width: 52px;
}
.col-static-icon {
  width: 35px;
}
.col-name, .col-Name {
  border-right: 1px solid #dbdbdb;
}
