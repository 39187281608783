.radio-button-filter {
  @extend %filter-styling;

  &:last-of-type {
    border-bottom: none;
  }

  .radio-button-input,
  .radio-button-filter-label,
  .radio-button-match-details {
    @extend %criterion-item;
  }

  .radio-button-input {
    z-index: 999;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    width: 16%;

    input {
      margin-left: 15px;
    }
  }

  .radio-button-filter-label {
    @extend %filter-label;
  }

  .radio-button-match-details {
    @extend %match-details;

    .radio-button-cumulative-matches {
      @extend %match-details-cumulative;
    }
  }
}
