$popover-z-index: 1060;

.popover {
  position: absolute;
  border: 2px solid #ccc;
  padding: 10px;
  background-color: #fff;
  z-index: $popover-z-index;
  .popover-header {
    display: block;
    padding-bottom: 5px;
    border-bottom: 2px dotted #dbdbdb;
    & > span {
      display: inline-block;
    }
    .popover-title {
      font-size: 12px;
      font-weight: bold;
      word-wrap: break-word;
      margin-right: 20px;
    }
    .popover-title-subtext {
      color: #555;
      font-size: 10px;
      margin-left: 5px;
    }
    .close-icon {
      position: absolute;
      right: 0px;
      top: 1px;
      height: 16px;
      width: 16px;
      cursor: pointer;
    }
  }
  .popover-body {
    > div {
      width: 100%;
    }
    display: table;
    border-collapse: separate;
    border-spacing: 5px 10px;
    margin: 0 -5px -10px -5px;
  }

  .popover-arrow {
    height: 16px;
    width: 16px;
    background-color: #fff;
    position: absolute;
    border: 2px solid transparent;
    border-top: 2px solid #ccc;
    border-left: 2px solid #ccc;
    z-index: $popover-z-index - 1;

    &.top {
      bottom: -8px;
      transform: rotate(-135deg);
    }
    &.bottom {
      top: -8px;
      transform: rotate(45deg);
    }

    &.left {
      right: -8px;
      transform: rotate(135deg);
    }
    &.right {
      left: -8px;
      transform: rotate(-45deg);
    }
  }
  &.error {
    white-space: normal;
    max-width: 350px;

    .error-container {
      border: 2px solid $negative;
      padding: 5px;
      margin: 0 20px 5px 0px;
      white-space: normal;
      .error-icon {
        position: relative;
        min-width: 20px;
      }
      .error-text {
        background: url(../media/images/error.gif) no-repeat scroll 1px 1px transparent;
        padding: 0 0 0 24px;
      }
    }
  }
}

div.popover:focus {
  outline: none !important;
}
