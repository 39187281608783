#view-all-criteria-groups {
  position: relative;
  max-height: calc(100% - #{$view-all-footer-height} - #{$view-all-header-height});
  max-width: 100%;
  overflow: auto;
  .column-container {
    margin-left: 10px;
    width: auto;
    height: auto;
    column-count: 2;
    column-gap: 0px;
  }
}

.view-all-criteria-group {
  box-sizing: border-box;
  display: inline-block;
  width: 95%;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 5px;
  margin-left: 5px;

  background-color: white;

  .view-all-criteria-group-column {
    display: inline-block;
    box-sizing: border-box;
    width: 50%;
    vertical-align: top;
    padding-bottom: 10px;
  }

  h3 {
    font-size: 18px;
    padding: 10px 0 12px 10px;
    margin: 0;
  }

  h4 {
    font-size: 12px;
  }

  ul {
    margin-top: 0;
  }
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  #view-all-criteria-groups {
    .column-container {
      column-count: 1; // IE does not recognize unset
      column-count: unset;
      margin-left: 0px;
    }
    height: calc(100% - #{$view-all-footer-height} - #{$view-all-header-mobile-height});
    .view-all-criteria-group {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}
