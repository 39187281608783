.quick-criteria-class-popup-content {
  .fidelity-content {
    padding: 20px;
    b {
      font-size: 1.7em;
    }
    p b {
      display: inline;
      margin-top: 30px;
      margin-bottom: 10px;
      font-size: 1em;
    }
    p a {
      display: inline-block;
    }
    p img {
      max-width: 100%;
    }
  }
}
