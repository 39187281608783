.fund-picks {
    display: block;
    max-width: 820px;
}

.fund-picks-header {
    font-size: 20px;
}

.fund-picks-info {
    margin-left: 85px;

    > p {
        margin-top: 20px;
    }

    ul {
        padding: 0;
        margin-top: 12px;
        padding-right: 15px;
        width: 50%;
        display: inline-block;
        vertical-align: top;
    }

    li {
        background: url("../media/images/checkmark.png") no-repeat left top;
        background-size: 20px;
        padding: 0 0 10px 30px;
        margin-bottom: 10px;
        list-style: none;
        
    }
}
.zero-categories-message{
    margin-left:8px;
}

.fund-picks-selector {
    padding: 20px;
    border-radius: 3px;
    border: solid 1px #ccc;
    font-size: 14px;
    margin-bottom:40px;

    .quick-criteria-class-popup {
        position: absolute;
        top: 20px;
        background: white;
        border: solid 1px $popup-border-grey-darker;
        min-width: 29vw;
        z-index: 9;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08), 0 1px 6px rgba(0, 0, 0, 0.12);
        max-height: 250px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    .quick-criteria-fund-type {
        border-right: none;
        padding: 0;

        .category-criterion {
            max-width: none;
            position: relative;
            display: flex;
            flex-wrap: nowrap;

            .view-results-button {
                align-self: flex-end;

                button {
                    width: 200px;
                }
            }
        }

        .asset-class-dropdown,
        .category-dropdown {
            flex: 1;
            margin: 0;
            margin-right: 3%;
        }

        .class-options,
        .category-options {
            height: 30px;
            padding: 5px 7px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 99%;
        }
    }

    .dropdown-arrows:before {
        display: none;
    }
    
    .dropdown-arrows:after {
        bottom: 10px;
    }

    .quick-criteria-category-popup{
        li.checkbox-input{
            padding:5px;
        }
        li {
            cursor: default;
        }
    }
}

.fund-picks-dropdown-wrap {
    display: inline-block;
    width: 80%;
}

.fund-picks-wrap {
    font-size: 16px;

    img {
        float: left;
        width: 55px;
        height: auto;
    }
}

.large-header {
    @extend %quick-criteria-large-header;
}

@media (max-width: $large-screen-width) {
    .quick-criteria-category-popup {
        min-width: 100%;
        max-height: 250px;
        overflow: auto;
    }
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
    .search-fund-picks-container {
        display: block;
        width: auto;
        border: none;
        padding: 0 0 20px 0;
    }
    .fund-picks-bullets {
        display: block;
        width: auto;
        border-top: solid 1px $medium-border-gray;
        padding: 20px 0 0 0;
    }
    
    .fund-picks-header {
        margin: 0 4%;
    }

    .fund-picks-info {
        margin: 0;

        p {
            margin-left: 55px;
        }

        ul {
            width: 100%;
            display: block;
            padding: 0;
        }
    }
    .fund-picks-wrap {
        margin: 0 4%;

        img {
            width: 40px;
        }
    }
    .fund-picks-selector {
        padding-bottom: 0;
        margin: 20px;
        margin-bottom:40px;

        .quick-criteria-class-popup {
            min-width: 100%;
        }
        .quick-criteria-fund-type {

            .class-options,
            .category-options {
                width: 100%;
            }
        
            .category-criterion {
                display: block;

                .view-results-button {
                    width: 100%;
                    align-self: auto;

                    button {
                        width: 100%;
                    }
                }
            }

            .asset-class-dropdown,
            .category-dropdown,
            .view-results-button {
                display: block;
                margin: 0 auto;
                flex: none;
                margin-bottom: 15px;
            }
        }
    }
}