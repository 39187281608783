.quick-criteria-fund-type {
  $radio-button-offset: 18px;
  border-right: 1.5px solid #cccccc;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 16px;

  .large-header {
    @extend %quick-criteria-large-header;
  }

  .category-criterion,
  .risk-criterion {
    max-width: 350px;
    margin-left: 9px;

    &.fund-picks-select {
      max-width: 100%;
      padding: 0;

      .asset-class-dropdown,
      .category-dropdown {
        margin-left: 0;
      }
    }
  }

  .disabled {
    cursor: not-allowed;
    color: $disabled-link;

    a {
      color: $disabled-link;
    }

    * {
      pointer-events: none;
    }

    .asset-class-dropdown .class-options.dropdown-arrows {
      &:before {
        border-bottom: 5px solid $disabled-link;
      }

      &:after {
        border-top: 5px solid $disabled-link;
      }
    }
  }

  .disabled-radio {
    .input label {
      color: $disabled-radio;
    }
  }

  .input {
    cursor: pointer;
    padding-top: 5px;

    &:first-of-type {
      margin-top: 5px;
    }

    input {
      cursor: pointer;
    }

    label {
      cursor: pointer;
      margin-left: 5px;
    }
  }

  .category-radio-selection {
    margin-top: 10px;
  }

  .category-criterion {
    margin-top: 10px;

    .asset-class-dropdown,
    .category-dropdown {
      position: relative;
      margin-left: $radio-button-offset;
    }

    .class-options,
    .category-options {
      cursor: pointer;
      margin-top: 5px;
      position: relative;
      border: solid 1px $asset-class-dropdown-border;
      padding: 11px 31px 11px 16px;
      border-radius: 5px;
      font-size: 15px;

      &.dropdown-arrows {
        &::before {
          display: none;
        }
        &::after {
          content: url(../media/images/class-dropdown-arrow.png);
          right: 31px;
          top: 50%;
          margin-top: -8.5px;
          border: none;
          height: 17px;
        }
      }
    }

    .category-dropdown {
      margin-top: 12px;

      &.disabled {
        .category-options {
          color: $disabled-link;
          cursor: not-allowed;

          &.dropdown-arrows {
            &:before {
              border-bottom: 5px solid $disabled-link;
            }

            &:after {
              border-top: 5px solid $disabled-link;
            }
          }
        }
      }
    }
  }

  .risk-radio-selection {
    margin-top: 25px;
  }
  .quick-criteria-risk-slider .slider-input {
    margin-left: 20px;
  }

  .description a {
    font-weight: normal;
    font-size: 13px;
    color: #0d58c2;
    margin-left: 12px;
  }

  .description {
    padding-left: 16px;
    padding-bottom: 12px;
  }
}

@media (max-width: $large-screen-width) {
  .quick-criteria-fund-type {
    padding: 0;
    justify-content: center;
    border-right: none;
    margin-left: 0px;
    .category-criterion,
    .risk-criterion {
      max-width: initial;
    }
  }
}

@media (max-width: $medium-screen-width), (max-width: $mobile-landscape-width) and (orientation: landscape) {
  .quick-criteria-fund-type {
    justify-content: center;
    border-right: none;
    margin-left: 0px;
    padding: 0;
    border-right: 1.5px black;
  }
}
